import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useState } from 'react';
import { classNames } from '../../helpers/classNames';
import styles from './style.module.scss';

interface Props {
    id?: string;
    name?: string;
    label?: string;
    type?: string;
    icon?: IconProp;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (param: any) => any;
    inputClassNames?: string;
    min?: any;
    max?: any;
    value?: string | number | readonly string[];
    isChecked?: boolean;
    disabled?: boolean;
    isRequired?: boolean;
    error?: string | null;
}

function Input(props: Props) {
    const {
        name,
        label,
        type,
        icon,
        placeholder,
        onChange,
        onBlur,
        isChecked,
        value = '',
        inputClassNames,
        disabled,
        max,
        error = '',
        isRequired,
    } = props;

    const [inputValue, setInputValue] = useState(value);
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        const val = value !== null && value !== undefined ? value : '';
        setInputValue(val);
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value: newValue } = event.target;

        if ((onChange && !max) || (onChange && max && newValue.length <= max)) {
            setInputValue(newValue);
            onChange(event);
        }
    };

    const handleBlur = () => {
        if (onBlur) {
            onBlur(inputValue);
        }
        setIsTouched(true);
    };

    return (
        <div className={classNames([styles.divInput])}>
            {label && (
                <label className={classNames([styles.labelInput])}>
                    {label}
                </label>
            )}
            {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
            <input
                name={name}
                className={classNames([
                    styles.input,
                    inputClassNames,
                    icon ? styles.inputIcon : '',
                ])}
                onChange={handleOnChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                type={type}
                checked={isChecked}
                value={inputValue}
                disabled={disabled}
                required={isRequired}
            />
            {max && (
                <span className={styles.valueLength}>
                    {inputValue?.toString()?.length}/{max}
                </span>
            )}
            {isTouched && error && error?.length > 0 && (
                <span className={styles.errorMessage}>{error}</span>
            )}
        </div>
    );
}

export default Input;
