import { classNames } from '../../helpers/classNames';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    label?: string;
    icon?: string;
    placeholder?: string;
    onChange?: (param: any) => void;
    onBlur?: (param: any) => void;
    inputClassNames?: string;
    value?: string;
    name?: string;
    id?: string;
    rows?: number;
}

function Textarea(props: Props) {
    const {
        label,
        icon,
        placeholder,
        onChange,
        onBlur,
        inputClassNames = styles.input,
        value = '',
        rows = 3,
        name,
        id,
    } = props;

    return (
        <div className={classNames([styles.divInput])}>
            {label && (
                <>
                    <label className={classNames([styles.labelInput])}>
                        {icon && <FaIcon>{icon}</FaIcon>}
                        {label}
                    </label>
                </>
            )}
            <textarea
                className={classNames([styles.input, inputClassNames])}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                rows={rows}
                name={name}
                defaultValue={value}
                id={id}
            ></textarea>
        </div>
    );
}

export default Textarea;
