import { useMutation, useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../../AlertProvider';
import Autocomplete from '../../../../components/Autocomplete';
import LoaderDot from '../../../../components/LoaderDot';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import OptionInterface from '../../../../interfaces/OptionInterface';
import { fetchLawyerBars } from '../../../../services/lawyerBarService';
import { updateLawyer } from '../../../../services/lawyerService';

interface Props {
    lawyerBarId: string;
}

function LawyerBar(props: Props) {
    const { lawyerBarId } = props;
    const alert = useAlert();

    const { isPending, data: lawyerBarsOptions } = useQuery({
        queryKey: ['myLawyerBar'],
        queryFn: async () => {
            try {
                const { data } = await fetchLawyerBars();
                return (
                    data.map((item: any) => {
                        const value: OptionInterface = {
                            label: item.name,
                            name: item.id,
                            id: item.id,
                        };
                        return value;
                    }) || []
                );
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const { mutate: addLawyerBar } = useMutation({
        mutationFn: async (id: string) => {
            if (id.length > 0) {
                const { data, status } = await updateLawyer({
                    lawyerBarId: id,
                });

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const onOptionSelected = (option: OptionInterface | undefined) => {
        if (option?.id) {
            addLawyerBar(option.id);
        }
    };

    if (isPending) {
        <LoaderDot />;
    }
    return (
        <div>
            <Autocomplete
                value={lawyerBarId}
                options={lawyerBarsOptions}
                onOptionClick={onOptionSelected}
                name={'lawyerBar'}
                label={'Barreau'}
            />
        </div>
    );
}

export default LawyerBar;
