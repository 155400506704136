import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
    faBuilding,
    faClock,
    faEnvelope,
    faPaperPlane,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import { cguvPage } from '../../constants/pagesRoutesContants';
import styles from './styles.module.scss';

const LiberAllContact = () => {
    return (
        <div className={styles.container}>
            {/* Hero Section */}
            <section className={styles.hero}>
                <div className={styles.heroContent}>
                    <h1 className={styles.heroTitle}>Contactez-nous</h1>
                    <p className={styles.heroSubtitle}>
                        Notre équipe est à votre disposition pour répondre à
                        toutes vos questions et vous accompagner dans vos
                        démarches juridiques.
                    </p>
                </div>
            </section>

            {/* Contact Information */}
            <section className={styles.section}>
                <div className={styles.sectionContent}>
                    <div className={styles.contactGrid}>
                        <div className={styles.contactInfo}>
                            <h2 className={styles.sectionTitle}>
                                Nos coordonnées
                            </h2>

                            <div className={styles.infoItems}>
                                {/* <div className={styles.infoItem}>
                                    <div
                                        className={`${styles.infoIcon} ${styles.blueIcon}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                        />
                                    </div>
                                    <div className={styles.infoContent}>
                                        <h3 className={styles.infoTitle}>
                                            Adresse
                                        </h3>
                                        <p className={styles.infoText}>
                                            123 Avenue de la Justice, 75008
                                            Paris, France
                                        </p>
                                    </div>
                                </div>

                                <div className={styles.infoItem}>
                                    <div
                                        className={`${styles.infoIcon} ${styles.orangeIcon}`}
                                    >
                                        <FontAwesomeIcon icon={faPhone} />
                                    </div>
                                    <div className={styles.infoContent}>
                                        <h3 className={styles.infoTitle}>
                                            Téléphone
                                        </h3>
                                        <p className={styles.infoText}>
                                            +33 (0)1 23 45 67 89
                                        </p>
                                    </div>
                                </div> */}

                                <div className={styles.infoItem}>
                                    <div
                                        className={`${styles.infoIcon} ${styles.blueIcon}`}
                                    >
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </div>
                                    <div className={styles.infoContent}>
                                        <h3 className={styles.infoTitle}>
                                            Email
                                        </h3>
                                        <p className={styles.infoText}>
                                            contact@liber-all.fr
                                        </p>
                                    </div>
                                </div>

                                <div className={styles.infoItem}>
                                    <div
                                        className={`${styles.infoIcon} ${styles.orangeIcon}`}
                                    >
                                        <FontAwesomeIcon icon={faClock} />
                                    </div>
                                    <div className={styles.infoContent}>
                                        <h3 className={styles.infoTitle}>
                                            Horaires d'ouverture
                                        </h3>
                                        <p className={styles.infoText}>
                                            Lundi - Vendredi: 9h00 - 18h00
                                        </p>
                                        <p className={styles.infoText}>
                                            Samedi - Dimanche: Fermé
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.socialContainer}>
                                <h3 className={styles.socialTitle}>
                                    Suivez-nous
                                </h3>
                                <div className={styles.socialIcons}>
                                    <Link
                                        to="#"
                                        className={`${styles.socialIcon} ${styles.facebook}`}
                                    >
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </Link>
                                    <Link
                                        to="#"
                                        className={`${styles.socialIcon} ${styles.twitter}`}
                                    >
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </Link>
                                    <Link
                                        to="#"
                                        className={`${styles.socialIcon} ${styles.linkedin}`}
                                    >
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </Link>
                                    <Link
                                        to="#"
                                        className={`${styles.socialIcon} ${styles.instagram}`}
                                    >
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className={styles.contactForm}>
                            <div className={styles.formCard}>
                                <h2 className={styles.formTitle}>
                                    Envoyez-nous un message
                                </h2>

                                <form>
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <div
                                                className={styles.inputWithIcon}
                                            >
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    label="Nom"
                                                    placeholder="Votre nom"
                                                    // value={email}
                                                    // onChange={handleChange}
                                                    // isRequired
                                                    // error={
                                                    //     emailError.errorMessage
                                                    // }
                                                    icon={faUser}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.formGroup}>
                                            <div
                                                className={styles.inputWithIcon}
                                            >
                                                <Input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    placeholder="votre@email.com"
                                                    // value={email}
                                                    // onChange={handleChange}
                                                    // isRequired
                                                    // error={
                                                    //     emailError.errorMessage
                                                    // }
                                                    icon={faEnvelope}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <div className={styles.inputWithIcon}>
                                            <Input
                                                type="text"
                                                id="lawfirm"
                                                name="lawfirm"
                                                label="Cabinet"
                                                placeholder="Votre cabinet"
                                                // value={email}
                                                // onChange={handleChange}
                                                // isRequired
                                                // error={emailError.errorMessage}
                                                icon={faBuilding}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label
                                            htmlFor="subject"
                                            className={styles.formLabel}
                                        >
                                            Sujet
                                        </label>
                                        <select
                                            id="subject"
                                            className={styles.formControl}
                                        >
                                            <option value="">
                                                Sélectionnez un sujet
                                            </option>
                                            <option value="information">
                                                Demande d'information
                                            </option>
                                            <option value="demo">
                                                Demande de démonstration
                                            </option>
                                            <option value="support">
                                                Support technique
                                            </option>
                                            <option value="partnership">
                                                Partenariat
                                            </option>
                                            <option value="other">Autre</option>
                                        </select>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <Textarea
                                            label="Messsage"
                                            id="message"
                                            rows={5}
                                            placeholder="Votre message"
                                        />
                                    </div>

                                    <div className={styles.formCheckbox}>
                                        <input
                                            type="checkbox"
                                            id="privacy"
                                            className={styles.checkbox}
                                        />
                                        <label
                                            htmlFor="privacy"
                                            className={styles.checkboxLabel}
                                        >
                                            J'accepte que mes données soient
                                            traitées conformément à la{' '}
                                            <Link
                                                to={cguvPage.path}
                                                reloadDocument
                                                className={styles.link}
                                            >
                                                politique de confidentialité
                                            </Link>
                                            .
                                        </label>
                                    </div>

                                    <button
                                        type="submit"
                                        className={styles.submitButton}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperPlane}
                                            className={styles.buttonIcon}
                                        />
                                        Envoyer le message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className={styles.section}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        Questions fréquentes
                    </h2>

                    <div className={styles.faqContainer}>
                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Comment puis-je prendre rendez-vous avec un
                                avocat ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Vous pouvez prendre rendez-vous directement via
                                notre plateforme en vous connectant à votre
                                compte client, ou en nous contactant par
                                téléphone ou email.
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Quels sont les délais de réponse à mes demandes
                                ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Notre équipe s'engage à répondre à toutes les
                                demandes dans un délai de 24 heures ouvrées.
                                Pour les questions urgentes, nous vous
                                recommandons de nous contacter par téléphone.
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Comment puis-je obtenir une démonstration de la
                                plateforme ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Vous pouvez demander une démonstration
                                personnalisée en remplissant le formulaire de
                                contact ci-dessus ou en nous appelant
                                directement. Un de nos conseillers vous guidera
                                à travers les fonctionnalités de Liber'All
                                adaptées à vos besoins.
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Proposez-vous des formations pour utiliser la
                                plateforme ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Oui, nous proposons des sessions de formation
                                gratuites pour tous nos nouveaux clients. Des
                                formations avancées sont également disponibles
                                pour les cabinets d'avocats souhaitant optimiser
                                leur utilisation de la plateforme.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className={styles.ctaSection}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.ctaTitle}>
                        Prêt à simplifier vos démarches juridiques ?
                    </h2>
                    <p className={styles.ctaText}>
                        Rejoignez les milliers d'avocats et de clients qui
                        utilisent déjà Liber'All pour faciliter leur quotidien.
                    </p>
                    <div className={styles.ctaButtons}>
                        <button className={styles.btnWhite}>
                            Créer un compte
                        </button>
                        <button className={styles.btnOrange}>
                            Demander une démo
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LiberAllContact;
