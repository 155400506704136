import OptionInterface from '../../interfaces/OptionInterface';
import styles from './style.module.scss';

interface Props {
    name: string;
    label?: string;
    options: OptionInterface[];
    checked?: string;
    onChange?: (param: any) => void;
    isDisabled?: boolean;
    isRequired?: boolean;
}

function Radio(props: Props) {
    const { name, label, options, checked, onChange, isDisabled } = props;
    return (
        <div className={styles.container}>
            <div>
                <label>{label} :</label>
            </div>
            <div className={styles.optionContainer}>
                {options.map((option) => (
                    <div key={option.name} className={styles.inputContainer}>
                        <input
                            key={option.name}
                            type="radio"
                            name={name}
                            value={option.name}
                            checked={checked === option.name}
                            onChange={onChange}
                            disabled={isDisabled}
                            className={styles.input}
                        />
                        <label htmlFor={option.name}>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Radio;
