import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Button from '../../../../../components/Button';
import FaIcon from '../../../../../components/Icons/FaIcon';
import Modal from '../../../../../components/Modal';
import Role from '../../../../../enums/RoleEnum';
import { classNames } from '../../../../../helpers/classNames';
import JudicialEventInterface from '../../../../../interfaces/JudicialEventInterface';
import { fetchJudicialCalendar } from '../../../../../services/judicialCalendarService';
import UserType from '../../../../../types/UserType';
import FormJudicialCalendar from './formJudicialCalendar';
import styles from './style.module.scss';

interface Props {
    clientCaseId: string;
}

function JudicialCalendar(props: Props) {
    const { clientCaseId } = props;
    const user: UserType = useOutletContext();
    const [judicialEvent, setJudicialEvent] =
        useState<JudicialEventInterface | null>(null);
    const [judicialCalendarModalOpened, setJudicialCalendarModalOpened] =
        useState(false);
    const today = new Date();

    const { data: dates } = useQuery({
        queryKey: [
            'judicialCalendar',
            clientCaseId,
            judicialCalendarModalOpened,
        ],
        queryFn: async () => {
            let i = 0;
            const { data } = await fetchJudicialCalendar(clientCaseId);
            if (data) {
                data?.forEach((event: any) => {
                    event.date = new Date(event.date);
                    if (today > event.date) {
                        event['style'] = styles.pastEvents;
                    } else if (i === 0) {
                        event['style'] = styles.nextEvent;
                        i++;
                    } else {
                        event['style'] = styles.futureEvents;
                    }
                });
                return data;
            }
            return [];
        },
    });

    const handleOpenFormClick = (event: JudicialEventInterface | null) => {
        setJudicialEvent(event);
        setJudicialCalendarModalOpened(true);
    };

    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Calendrier Judiciaire:</span>

                {user?.role === Role.Lawyer && (
                    <Button onClick={handleOpenFormClick}>
                        <FaIcon>fa-add</FaIcon>
                        Ajouter
                    </Button>
                )}
            </h2>
            {user?.role === Role.Lawyer && (
                <Modal
                    opened={judicialCalendarModalOpened}
                    setOpened={setJudicialCalendarModalOpened}
                    title={
                        judicialEvent === null
                            ? 'Nouvelle date'
                            : 'Modification ' + judicialEvent.title
                    }
                >
                    <FormJudicialCalendar
                        setModalOpened={setJudicialCalendarModalOpened}
                        clientCaseId={clientCaseId}
                        judicialEvent={judicialEvent}
                    />
                </Modal>
            )}
            <div className={styles.judicialCalendar}>
                {dates?.map((event: any) => (
                    <div
                        key={event?.id}
                        className={classNames([
                            styles.judicialCalendarItem,
                            event?.style,
                        ])}
                    >
                        {user?.role === Role.Lawyer && (
                            <div className={styles.actionBtn}>
                                <Button
                                    variant="primaryLight"
                                    onClick={() => {
                                        handleOpenFormClick(event);
                                    }}
                                >
                                    <FaIcon>fa-edit</FaIcon>
                                </Button>
                                <Button variant="primaryLight">
                                    <FaIcon>fa-close</FaIcon>
                                </Button>
                            </div>
                        )}
                        <div className={styles.judicialCalendarContent}>
                            <span className={styles.judicialCalendarTitle}>
                                {event?.title}
                            </span>
                            <span className={styles.judicialCalendarDate}>
                                {event?.date.toLocaleString()}
                            </span>
                            <span
                                className={styles.judicialCalendarDescription}
                            >
                                {event?.description}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default JudicialCalendar;
