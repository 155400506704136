import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '../../../../AlertProvider';
import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import { alertType } from '../../../../constants/alertTypeConstant';
import { formElements } from '../../../../constants/formContants';
import { formsPage } from '../../../../constants/pagesRoutesContants';
import { successStatus } from '../../../../constants/requestStatusContants';
import { classNames } from '../../../../helpers/classNames';
import CreateFormInterface from '../../../../interfaces/CreateFormInterface';
import OptionInterface from '../../../../interfaces/OptionInterface';
import {
    deleteForm,
    fetchForm,
    postForm,
    updateForm,
} from '../../../../services/formService';
import FormElementType from '../../../../types/FormElementType';
import FormContainer from './FormContainer';
import NameDescriptionForm from './NameDescriptionForm';
import SelectFormElement from './SelectFormElement';
import styles from './style.module.scss';

function EditorForm() {
    const { formId = '' } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [selectedElement, setSelectedElement] = useState<string>('');
    const [options, setOptions] = useState<OptionInterface[]>([]);
    const [requiredElement, setRequiredElement] = useState(false);
    const alert = useAlert();
    const [selectedFormElements, setSelectedFormElements] = useState<
        FormElementType[]
    >([]);
    const [formName, setFormName] = useState('');
    const [formDescription, setFormDescription] = useState('');

    const { isPending } = useQuery({
        queryKey: ['form'],
        queryFn: async () => {
            try {
                const { data } = await fetchForm(formId);
                setFormName(data.name || '');
                setFormDescription(data.description || '');
                setSelectedFormElements(data.listFormComponents || []);
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const { mutate: saveForm } = useMutation({
        mutationFn: async () => {
            let errorMessages = '';

            if (formName.length === 0) {
                errorMessages = 'Veuillez donner un nom à votre formulaire';
                alert.show({
                    children: errorMessages,
                    type: 'error',
                });
                return;
            }

            if (selectedFormElements.length === 0) {
                errorMessages =
                    'Veuillez ajouter des éléments à votre formulaire';
                alert.show({
                    children: errorMessages,
                    type: 'error',
                });
                return;
            }

            const newForm: CreateFormInterface = {
                name: formName,
                description: formDescription,
                listFormComponents: selectedFormElements,
            };

            if (!!formElements && formId.length > 0) {
                const { data, status } = await updateForm(formId, newForm);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Votre formulaire a été enregistré avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            } else {
                const { data, status } = await postForm(newForm);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Votre formulaire a été enregistré avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const { mutate: deleteSelectedForm } = useMutation({
        mutationFn: async () => {
            if (!!formElements && formId.length > 0) {
                const { data, status } = await deleteForm(formId);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Votre formulaire a été supprimé avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const handleCloseForm = () => {
        navigate(formsPage.path);
    };

    const handleAddClick = () => {
        if (selectedElement.length === 0) {
            return;
        }
        const newElement: FormElementType = {
            id: new Date().getMilliseconds.toString(),
            formComponentElement: selectedElement,
            name: name || formElements[selectedElement].name,
            label: name || formElements[selectedElement].label,
            isRequired: requiredElement,
            position: selectedElement.length - 1,
        };
        if (options.length > 0) {
            newElement.options = options;
        }

        setSelectedFormElements([...selectedFormElements, newElement]);
        setOptions([]);
        setName('');
        setSelectedElement('');
        setRequiredElement(false);
    };

    return (
        <div className={styles.formEditor}>
            <h1>
                {formId?.length === 0 ? 'Créez' : 'Modifiez'} votre formulaire
            </h1>
            <Divider />
            <div className={styles.actionContainer}>
                <div className={styles.actionBtn}>
                    <div>
                        <Button onClick={handleCloseForm}>
                            <FontAwesomeIcon icon={faCaretLeft} /> Retour
                        </Button>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <div className={styles.formActionContainer}>
                        <SelectFormElement
                            name={name}
                            setName={setName}
                            requiredElement={requiredElement}
                            setRequiredElement={setRequiredElement}
                            selectedElement={selectedElement}
                            setSelectedElement={setSelectedElement}
                            options={options}
                            setOptions={setOptions}
                            onAddClick={handleAddClick}
                        />
                        <div className={styles.dataActionBtn}>
                            <Button
                                className={styles.btn}
                                onClick={saveForm}
                                icon="fa-save"
                            >
                                Enregister
                            </Button>
                            <Button
                                className={classNames([
                                    styles.btn,
                                    styles.delete,
                                ])}
                                onClick={deleteSelectedForm}
                                icon="fa-trash"
                            >
                                Supprimer
                            </Button>
                        </div>
                    </div>
                    <div className={styles.formDataContainer}>
                        <NameDescriptionForm
                            formDescription={formDescription}
                            setFormDescription={setFormDescription}
                            formName={formName}
                            setFormName={setFormName}
                        />
                        {!isPending && (
                            <FormContainer
                                form={selectedFormElements}
                                setForm={setSelectedFormElements}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditorForm;
