import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from '../../../../AlertProvider';
import ButtonLink from '../../../../components/ButtonLink';
import Loader from '../../../../components/Loader';
import NotFound from '../../../../components/NotFound';
import Tabs from '../../../../components/Tabs';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import { postContactClientBookmark } from '../../../../services/bookmarkService';
import { fetchFormsResponsesByUserId } from '../../../../services/formResponseService';
import { fetchUserById } from '../../../../services/userService';
import BillsTable from '../billsTable';
import CasesTable from '../casesTable';
import ClientCaseFormsResponsesTable from '../clientCaseDetails/clientCaseFormResponses/clientCaseFormResponsesTable';
import MeetingsTable from '../meetingsTable';
import styles from './style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => void;
    userId: string;
}

function ClientDetails(props: Props) {
    // ajouter une page de détails des étapes du dossier
    const { setToolbar, userId } = props;
    const alert = useAlert();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const page: string = pathname.replace('/' + userId, '');

    const { data: user, isPending } = useQuery({
        queryKey: ['getUser', userId],
        queryFn: async () => {
            if (!userId) {
                return null;
            }
            const { data } = await fetchUserById(userId);
            return data;
        },
    });

    const { data: formResponses } = useQuery({
        queryKey: ['formsResponses'],
        queryFn: async () => {
            try {
                const { data } = await fetchFormsResponsesByUserId(userId);
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const { mutate } = useMutation({
        mutationFn: async (id: string) => {
            const { data, status } = await postContactClientBookmark(id);
            if (successStatus.includes(status)) {
                alert.show({
                    children:
                        'Vos modifications ont été enregistrées avec succès',
                    type: alertType[status],
                });
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
            return data;
        },
    });

    useEffect(() => {
        const handleClose = () => {
            navigate(page);
        };

        const handleClickFavori = () => {
            mutate(userId);
        };
        const caseToolbar: NavDataInterface[] = [
            {
                id: 'caseToolbar1',
                iconType: 'regular',
                icon: 'fa-star',
                label: 'Mettre en Favori',
                onClick: handleClickFavori,
                isDisabled: !user,
            },
            {
                id: 'caseToolbar9',
                icon: 'fa-remove',
                label: 'Fermer',
                onClick: handleClose,
                position: 'end',
            },
        ];

        setToolbar(caseToolbar);
    }, [page, setToolbar, user, mutate, userId, navigate]);

    if (isPending) {
        return <Loader />;
    }
    if (!user) {
        return <NotFound />;
    }

    return (
        <div className={styles.body}>
            <div className={styles.userInfoContainer}>
                <div className={styles.userProfile}>
                    <img
                        src="https://via.placeholder.com/150"
                        alt="UserProfilePicture"
                        className={styles.profilePicture}
                    />
                    <h2 className={styles.userName}>
                        {user.firstName} {user.lastName}
                    </h2>
                </div>
                <div className={styles.userDetails}>
                    <div className={styles.contactRow}>
                        <div className={styles.contactRowTitle}>
                            <strong>Adresse :</strong>
                        </div>
                        <div className={styles.contactRowData}>
                            {user.address}
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contactRowTitle}>
                            <strong>Email :</strong>
                        </div>
                        <div className={styles.contactRowData}>
                            {user.email}
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contactRowTitle}>
                            <strong>Téléphone :</strong>
                        </div>
                        <div className={styles.contactRowData}>
                            {user.phone}
                        </div>
                    </div>
                    <div className={styles.contactBtn}>
                        <ButtonLink link={'/profile/messages'}>
                            Contacter
                        </ButtonLink>
                    </div>
                </div>
            </div>
            <div className={styles.tabsContainer}>
                <Tabs
                    activeName="cases"
                    tabs={[
                        {
                            name: 'cases',
                            content: <CasesTable userId={userId} />,
                            title: 'Dossiers',
                        },
                        {
                            name: 'meetings',
                            content: <MeetingsTable userId={userId} />,
                            title: 'Rendez-vous',
                        },
                        {
                            name: 'forms',
                            content: (
                                <ClientCaseFormsResponsesTable
                                    formResponses={formResponses}
                                />
                            ),
                            title: 'Formulaires',
                        },
                        {
                            name: 'bills',
                            content: <BillsTable userId={userId} />,
                            title: 'Factures',
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default ClientDetails;
