/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import CreateFormResponseFieldInterface from '../../interfaces/CreateFormResponseFieldInterface';
import FormElementType from '../../types/FormElementType';
import Button from '../Button';
import DynamicFormElements from '../DynamicFormElements';
import styles from './style.module.scss';

interface Props {
    form?: FormElementType[];
    onSave?: (param: CreateFormResponseFieldInterface[]) => void;
    onCancel?: () => void;
}

function DynamicForm(props: Props) {
    const { form, onCancel, onSave } = props;
    const [formResponsesFieds, setFormResponsesFieds] = useState<
        CreateFormResponseFieldInterface[]
    >([]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const errors: CreateFormResponseFieldInterface[] = [];
        formResponsesFieds.forEach((item: CreateFormResponseFieldInterface) => {
            if (item.isRequired && item.value.length === 0) {
                errors.push(item);
            }
        });

        if (
            (form && formResponsesFieds.length < form.length) ||
            errors.length > 0
        ) {
            console.log('Error', { errors: errors });
        }

        if (onSave) {
            onSave(formResponsesFieds);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.container}>
            <div className={styles.formContainer}>
                <div className={styles.formElementsContainer}>
                    {form && (
                        <DynamicFormElements
                            form={form}
                            setFormResponsesFields={setFormResponsesFieds}
                            formResponsesFieds={formResponsesFieds}
                        />
                    )}
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button variant={'primaryLightBordered'} onClick={onCancel}>
                    Annuler
                </Button>
                <Button type="submit">Envoyer</Button>
            </div>
        </form>
    );
}
export default DynamicForm;
