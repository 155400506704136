import { formElements } from '../../constants/formContants';
import OptionInterface from '../../interfaces/OptionInterface';
import FormElementType from '../../types/FormElementType';

interface Props {
    element: FormElementType;
    onChange?: (param: any, data?: any) => void;
    value?: any;
}

function Element(props: Props) {
    const { element, onChange, value } = props;

    const type = element.formComponentElement;
    const Element = formElements[type].element;
    let checked: undefined | OptionInterface[] = undefined;
    if (type === 'checkbox') {
        checked = value;
    }
    console.log(element);

    return (
        <Element
            {...element}
            onChange={onChange}
            value={value}
            checked={checked}
        />
    );
}

export default Element;
