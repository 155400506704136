import { useQuery } from '@tanstack/react-query';
import { ChangeEvent } from 'react';
import { storageItems } from '../../../constants/storageItemsContants';
import OptionInterface from '../../../interfaces/OptionInterface';
import { fetchSpecialities } from '../../../services/specialityService';
import SpecialityType from '../../../types/SpecialityType';
import Autocomplete from '../../Autocomplete';

interface Props {
    onOptionSelected: (value: SpecialityType | null) => void;
    inputClassNames?: any;
    label?: string;
    placeholder?: string;
    onChange?: (value: any) => any;
    speciality?: string;
    reset?: boolean;
}

function SearchBySpeciality(props: Props) {
    const {
        onOptionSelected,
        inputClassNames,
        label = '',
        placeholder,
        onChange,
        speciality,
        reset,
    } = props;

    const { isPending, data: specialities } = useQuery({
        queryKey: ['specialities'],
        queryFn: async () => {
            try {
                const { data } = await fetchSpecialities();
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const specialitiesOptions: OptionInterface[] = specialities?.map(
        (speciality: SpecialityType) => {
            return {
                id: speciality.id,
                name: speciality.name,
                label: speciality.name,
                data: speciality,
            };
        }
    );

    const handleChangeSpeciality = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
    };
    function handleOptionClick(option?: OptionInterface) {
        sessionStorage.setItem(storageItems.speciality, option?.data.id);
        onOptionSelected(option?.data);
    }

    return (
        <Autocomplete
            options={specialitiesOptions}
            label={label ? label : 'Spécialité'}
            placeholder={placeholder}
            onChange={handleChangeSpeciality}
            onOptionClick={handleOptionClick}
            value={speciality || ''}
            inputClassNames={inputClassNames}
            name="speciality"
            reset={reset}
            isDataLoading={isPending}
        />
    );
}

export default SearchBySpeciality;
