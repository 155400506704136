import AddressType from '../../types/AddressType';
import LawfirmType from '../../types/LawfirmType';
import LawyerType from '../../types/LawyerType';
import SpecialityType from '../../types/SpecialityType';
import Button from '../Button';
import SearchByAddress from './SearchByAddress';
import SearchByLawfirm from './SearchByLawfirm';
import SearchByLawyer from './SearchByLawyer';
import SearchBySpeciality from './SearchBySpeciality';
import styles from './style.module.scss';

interface Props {
    setParams?: (param: any) => any;
    params?: any;
    onChange?: (param: any) => any;
    onSearch?: (param: any) => any;
}

function SearchBar(props: Props) {
    const { onChange, onSearch, setParams, params } = props;

    const handleSelectLawyer = (selectedOption: LawyerType | null) => {
        const newParams: { [key: string]: any } = { ...params };
        newParams['lawyer'] = selectedOption?.id;
        if (setParams) {
            setParams(newParams);
        }
    };
    const handleSelectLawfirm = (selectedOption: LawfirmType | null) => {
        const newParams: { [key: string]: any } = { ...params };
        newParams['lawfirm'] = selectedOption?.id;
        if (setParams) {
            setParams(newParams);
        }
    };
    const handleSelectSpeciality = (selectedOption: SpecialityType | null) => {
        const newParams: { [key: string]: any } = { ...params };
        newParams['speciality'] = selectedOption?.id;
        if (setParams) {
            setParams(newParams);
        }
    };
    const handleSelectAddress = (selectedOption: AddressType | null) => {
        const newParams: { [key: string]: any } = { ...params };
        newParams['latitude'] = selectedOption?.latitude;
        newParams['longitude'] = selectedOption?.longitude;
        newParams['address'] = selectedOption?.address;
        if (setParams) {
            setParams(newParams);
        }
    };

    return (
        <div className={styles.searchBar}>
            {handleSelectAddress && (
                <div className={styles.searchInput}>
                    <SearchByAddress
                        onOptionSelected={handleSelectAddress}
                        userAddress={params?.address}
                        onChange={onChange}
                    />
                </div>
            )}
            {handleSelectSpeciality && (
                <div className={styles.searchInput}>
                    <SearchBySpeciality
                        onOptionSelected={handleSelectSpeciality}
                        onChange={onChange}
                        speciality={params?.speciality}
                    />
                </div>
            )}
            {handleSelectLawfirm && (
                <div className={styles.searchInput}>
                    <SearchByLawfirm
                        onOptionSelected={handleSelectLawfirm}
                        onChange={onChange}
                    />
                </div>
            )}
            {handleSelectLawyer && (
                <div className={styles.searchInput}>
                    <SearchByLawyer
                        onOptionSelected={handleSelectLawyer}
                        onChange={onChange}
                    />
                </div>
            )}
            {/* <Autocomplete options={[]} placeholder="Prix de la consultation" /> */}
            {/* service.lenght > 0 && {<Autocomplete placeholder="Service" />} */}
            {onSearch && <Button onClick={onSearch}>Chercher</Button>}
        </div>
    );
}

export default SearchBar;
