import { faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import Button from '../../../../../../components/Button';
import DynamicFormResponse from '../../../../../../components/DynamicFormResponse';
import Modal from '../../../../../../components/Modal';
import { requestStatus } from '../../../../../../constants/requestStatusContants';
import { fetchFormResponseById } from '../../../../../../services/formResponseService';
import { FormResponseType } from '../../../../../../types/FormResponse';
import styles from './style.module.scss';

interface Props {
    formResponses: FormResponseType[];
}

function ClientCaseFormsResponsesTable(props: Props) {
    const { formResponses } = props;
    const [idForm, setIdForm] = useState<string>('');
    const [formResponse, setFormResponse] = useState<
        FormResponseType | undefined
    >(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { mutate: getFormResponse } = useMutation({
        mutationFn: async (id: string) => {
            if (!id) return null;
            const result = await fetchFormResponseById(id);
            if (result?.status === requestStatus.OK) {
                setFormResponse(result.data);
                setShowModal(true);
            }
            return result;
        },
    });

    const handleOpenModal = (id: string) => {
        setIdForm(idForm);
        getFormResponse(id);
    };

    return (
        <div>
            <Modal opened={showModal} setOpened={setShowModal}>
                <div className={styles.form}>
                    {formResponse && formResponse?.fields.length > 0 && (
                        <DynamicFormResponse
                            formName={formResponse.name}
                            formResponseFields={formResponse?.fields}
                        />
                    )}
                </div>
            </Modal>
            <table>
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">nom</th>
                        <th scope="col">status</th>
                        <th scope="col">date creation</th>
                        <th scope="col">date de réponse</th>
                        <th scope="col">action</th>
                    </tr>
                </thead>
                <tbody className={styles.events}>
                    {formResponses?.map((formResponse) => (
                        <tr className={styles.event} key={formResponse.id}>
                            <td className={styles.iconCol}>
                                <FontAwesomeIcon icon={faFileText} />
                            </td>
                            <td>{formResponse.name}</td>
                            <td>
                                {formResponse.editDate ? 'Reçu' : 'En attente'}
                            </td>
                            <td>
                                {new Date(
                                    formResponse.creationDate
                                )?.toLocaleDateString()}
                            </td>
                            <td>
                                {formResponse.editDate &&
                                    new Date(
                                        formResponse.editDate
                                    )?.toLocaleDateString()}
                            </td>
                            <td>
                                {formResponse.editDate && (
                                    <div className={styles.actionBtn}>
                                        <Button
                                            onClick={() =>
                                                handleOpenModal(formResponse.id)
                                            }
                                        >
                                            Ouvrir
                                        </Button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ClientCaseFormsResponsesTable;
