import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useState } from 'react';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import Radio from '../../../../../components/Radio';
import { formElements } from '../../../../../constants/formContants';
import OptionInterface from '../../../../../interfaces/OptionInterface';
import OptionForm from './OptionForm';
import styles from './style.module.scss';

interface Props {
    name: string;
    setName: (param: string) => void;
    requiredElement: boolean;
    setRequiredElement: (param: boolean) => void;
    selectedElement: string;
    setSelectedElement: (param: string) => void;
    options: OptionInterface[];
    setOptions: (param: OptionInterface[]) => void;
    onAddClick: () => void;
}

function SelectFormElement(props: Props) {
    const {
        name,
        setName,
        requiredElement,
        setRequiredElement,
        setSelectedElement,
        selectedElement,
        options,
        setOptions,
        onAddClick,
    } = props;
    const [showInputValues, setShowInputValues] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);
    };

    const handleElementClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name, textContent } = event.currentTarget;
        setName(textContent || '');
        setModalOpened(true);
        setSelectedElement(name);
        const multipleValueOptions = ['select', 'radio', 'checkbox'];
        setShowInputValues(multipleValueOptions.includes(name));
    };

    const handleChangeRequired = (event: ChangeEvent<HTMLInputElement>) => {
        setRequiredElement(event.target.value === 'Oui');
    };

    const closeDropdown = () => {
        setModalOpened(false);
        setSelectedElement('');
    };

    const handleAddClick = () => {
        onAddClick();
        closeDropdown();
    };

    return (
        <div className={styles.container}>
            <div className={styles.elementsContainer}>
                {Object.keys(formElements).map((x) => (
                    <Button
                        key={formElements[x].name}
                        name={formElements[x].name}
                        onClick={handleElementClick}
                        variant={
                            selectedElement === formElements[x].name
                                ? 'primary'
                                : 'secondary'
                        }
                        icon={formElements[x].icon}
                    >
                        {formElements[x].label}
                    </Button>
                ))}
            </div>
            {modalOpened && (
                <div className={styles.dropdown}>
                    <Button
                        className={styles.btnClose}
                        onClick={closeDropdown}
                        variant="secondaryLight"
                    >
                        <FontAwesomeIcon icon={faRemove} />
                    </Button>
                    <div className={styles.modalBody}>
                        <Input
                            onChange={handleChangeName}
                            label="Nom de l'élément"
                            value={name}
                        />
                        {showInputValues && (
                            <OptionForm
                                options={options}
                                setOptions={setOptions}
                            />
                        )}
                        <Radio
                            checked={requiredElement ? 'Oui' : 'Non'}
                            onChange={handleChangeRequired}
                            options={[
                                { name: 'Oui', label: 'Oui' },
                                { name: 'Non', label: 'Non' },
                            ]}
                            name="required"
                            label="Obligatoire"
                        />
                    </div>
                    <div className={styles.footer}>
                        <Button
                            variant="primaryLightBordered"
                            onClick={closeDropdown}
                        >
                            Annuler
                        </Button>
                        <Button onClick={handleAddClick}>Ajouter</Button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default SelectFormElement;
