import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../../../../AlertProvider';
import { useAuth } from '../../../../AuthProvider';
import Button from '../../../../components/Button';
import FaIcon from '../../../../components/Icons/FaIcon';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import Role from '../../../../enums/RoleEnum';
import { GetTimeGapString } from '../../../../helpers/date';
import { confirmationMeeting } from '../../../../services/meetingService';
import ConfirmationType from '../../../../types/ConfirmationType';
import MeetingType from '../../../../types/MeetingDateType';
import styles from './style.module.scss';

interface Props {
    events: MeetingType[];
}

function MeetingsConfirmationTable(props: Props) {
    const { events } = props;
    const alert = useAlert();
    const { user } = useAuth();

    const { mutate } = useMutation({
        mutationFn: async (confirmation: ConfirmationType) => {
            try {
                const { data, status } =
                    await confirmationMeeting(confirmation);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Le rendez-vous a été confirmé avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    // const meetingAction = {
    //   cancel: { name: "cancel", value: false },
    //   confirm: { name: "confirm", value: true },
    // };
    const handleValidateMeetingClick = (
        id: string | undefined,
        isConfirmed: boolean
    ) => {
        if (id) {
            mutate({ id, isConfirmed });
        }
    };

    return (
        <div className={styles.meetings}>
            <table>
                <thead>
                    <tr>
                        <th scope="col">
                            {user?.role === Role.Lawyer ? 'Client' : 'Avocat'}
                        </th>
                        <th scope="col">Type rendez-vous</th>
                        <th scope="col">Date</th>
                        <th scope="col">Durée</th>
                        <th scope="col">Sujet</th>
                        {user?.role === Role.Lawyer && (
                            <th scope="col">Action</th>
                        )}
                    </tr>
                </thead>
                <tbody className={styles.events}>
                    {events?.map((event) => (
                        <tr className={styles.event} key={event.id}>
                            <td>
                                {user?.role === Role.Lawyer
                                    ? event.client
                                    : event.lawyer}
                            </td>
                            <td>{event.title}</td>
                            <td>{new Date(event.start).toLocaleString()}</td>
                            <td>{GetTimeGapString(event.start, event.end)}</td>
                            <td>{event.subject}</td>
                            {user?.role === Role.Lawyer && (
                                <td>
                                    <div className={styles.action}>
                                        <Button
                                            variant="primaryLight"
                                            onClick={() =>
                                                handleValidateMeetingClick(
                                                    event.id,
                                                    true
                                                )
                                            }
                                        >
                                            <FaIcon>fa-check</FaIcon>
                                        </Button>
                                        <Button
                                            variant="primaryLight"
                                            onClick={() =>
                                                handleValidateMeetingClick(
                                                    event.id,
                                                    true
                                                )
                                            }
                                        >
                                            <FaIcon>fa-remove</FaIcon>
                                        </Button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MeetingsConfirmationTable;
