'use client';

import {
    faBolt,
    faCalculator,
    faCheck,
    faDiamond,
    faGift,
    faInfoCircle,
    faMoneyBill,
    faRocket,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export default function Offers() {
    // État pour le type de facturation
    const [billingType, setBillingType] = useState<
        'monthly' | 'quarterly' | 'yearly'
    >('monthly');

    // États pour le simulateur
    const [selectedPlan, setSelectedPlan] = useState<
        'basique' | 'avancee' | 'premium' | 'acte'
    >('basique');
    const [appointmentPrice, setAppointmentPrice] = useState<number>(100);
    const [appointmentCount, setAppointmentCount] = useState<number>(10);
    const [simulationResult, setSimulationResult] = useState<{
        subscriptionCost: number;
        appointmentCost: number;
        totalCost: number;
        savings: number;
    }>({
        subscriptionCost: 0,
        appointmentCost: 0,
        totalCost: 0,
        savings: 0,
    });

    // Calcul du simulateur
    useEffect(() => {
        let subscriptionCost = 0;
        let appointmentCost = 0;
        let includedAppointments = 0;
        let commissionRate = 0;

        // Déterminer le coût de l'abonnement et les RDV inclus
        switch (selectedPlan) {
            case 'basique':
                subscriptionCost =
                    billingType === 'monthly'
                        ? 29
                        : billingType === 'quarterly'
                          ? 27 * 3
                          : 24 * 12;
                includedAppointments = 5;
                commissionRate = 0.1; // 10%
                break;
            case 'avancee':
                subscriptionCost =
                    billingType === 'monthly'
                        ? 69
                        : billingType === 'quarterly'
                          ? 65 * 3
                          : 59 * 12;
                includedAppointments = 15;
                commissionRate = 0.05; // 5%
                break;
            case 'premium':
                subscriptionCost =
                    billingType === 'monthly'
                        ? 149
                        : billingType === 'quarterly'
                          ? 142 * 3
                          : 129 * 12;
                includedAppointments = Number.MAX_SAFE_INTEGER; // Illimité
                commissionRate = 0; // 0%
                break;
            case 'acte':
                subscriptionCost = 0;
                includedAppointments = 0;
                commissionRate = 1; // 100% (40€ par RDV)
                break;
        }

        // Calculer le coût des RDV supplémentaires
        const extraAppointments = Math.max(
            0,
            appointmentCount - includedAppointments
        );

        if (selectedPlan === 'acte') {
            appointmentCost = appointmentCount * 40; // 40€ par RDV
        } else {
            appointmentCost =
                extraAppointments * appointmentPrice * commissionRate;
        }

        // Calculer le coût total
        const totalCost = subscriptionCost + appointmentCost;

        // Calculer les économies par rapport au paiement à l'acte
        const payPerAppointmentCost = appointmentCount * 40;
        const savings = payPerAppointmentCost - totalCost;

        setSimulationResult({
            subscriptionCost,
            appointmentCost,
            totalCost,
            savings,
        });
    }, [selectedPlan, billingType, appointmentPrice, appointmentCount]);

    return (
        <div className={styles.container}>
            {/* Header - Simplifié pour l'exemple */}
            <header className={styles.header}>
                <div className={styles.headerContent}>
                    <Link to="/" className={styles.logo}>
                        Liber'All
                    </Link>
                    <nav className={styles.navigation}>
                        <Link to="/" className={styles.navLink}>
                            Accueil
                        </Link>
                        <Link to="/fonctionnalites" className={styles.navLink}>
                            Fonctionnalités
                        </Link>
                        <Link
                            to="/offres"
                            className={`${styles.navLink} ${styles.active}`}
                        >
                            Offres
                        </Link>
                        <Link to="/contact" className={styles.navLink}>
                            Contact
                        </Link>
                    </nav>
                </div>
            </header>

            {/* Hero Section */}
            <section className={styles.hero}>
                <div className={styles.heroContent}>
                    <h1 className={styles.heroTitle}>Nos offres et tarifs</h1>
                    <p className={styles.heroSubtitle}>
                        Des solutions adaptées à tous les profils, que vous
                        soyez avocat indépendant ou cabinet structuré.
                    </p>
                </div>
            </section>

            {/* Introduction */}
            <section className={styles.section}>
                <div className={styles.sectionContent}>
                    <div className={styles.introContainer}>
                        <h2 className={styles.sectionTitle}>
                            Une tarification transparente et sans surprise
                        </h2>
                        <p className={styles.introText}>
                            Liber'All propose des formules d'abonnement adaptées
                            à vos besoins, avec la possibilité d'évoluer à tout
                            moment selon votre activité. Tous nos forfaits
                            incluent un support client et des mises à jour
                            régulières.
                        </p>
                        <div className={styles.tagContainer}>
                            <span className={`${styles.tag} ${styles.tagBlue}`}>
                                Sans engagement
                            </span>
                            <span
                                className={`${styles.tag} ${styles.tagOrange}`}
                            >
                                Essai gratuit disponible
                            </span>
                            <span
                                className={`${styles.tag} ${styles.tagGreen}`}
                            >
                                Support inclus
                            </span>
                        </div>
                        <div className={styles.infoBox}>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className={styles.infoIcon}
                            />
                            <p className={styles.infoText}>
                                <strong>Offre spéciale :</strong> Bénéficiez de
                                5% de réduction sur les abonnements trimestriels
                                et 15% sur les abonnements annuels.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Pricing Toggle */}
            <section className={styles.toggleSection}>
                <div className={styles.sectionContent}>
                    <div className={styles.billingToggle}>
                        <button
                            onClick={() => setBillingType('monthly')}
                            className={`${styles.toggleButton} ${billingType === 'monthly' ? styles.active : ''}`}
                        >
                            Mensuel
                        </button>
                        <button
                            onClick={() => setBillingType('quarterly')}
                            className={`${styles.toggleButton} ${billingType === 'quarterly' ? styles.active : ''}`}
                        >
                            Trimestriel (-5%)
                        </button>
                        <button
                            onClick={() => setBillingType('yearly')}
                            className={`${styles.toggleButton} ${billingType === 'yearly' ? styles.active : ''}`}
                        >
                            Annuel (-15%)
                        </button>
                    </div>
                </div>
            </section>

            {/* Pricing Plans */}
            <section className={styles.section}>
                <div className={styles.sectionContent}>
                    <div className={styles.pricingGrid}>
                        {/* Plan Test Gratuit */}
                        <div className={styles.pricingCard}>
                            <div
                                className={`${styles.pricingHeader} ${styles.testHeader}`}
                            >
                                <div className={styles.iconContainer}>
                                    <FontAwesomeIcon
                                        icon={faGift}
                                        className={styles.planIcon}
                                    />
                                </div>
                                <h3 className={styles.planTitle}>
                                    Test Gratuit
                                </h3>
                                <p className={styles.planSubtitle}>
                                    Pour découvrir la plateforme
                                </p>
                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>0€</span>
                                </div>
                                <p className={styles.billingInfo}>
                                    14-30 jours d'essai
                                </p>
                            </div>
                            <div className={styles.pricingBody}>
                                <ul className={styles.featuresList}>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>3 RDV offerts</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>1 Go de stockage</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                        <span
                                            className={styles.featureExcluded}
                                        >
                                            Automatisation documents
                                        </span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>Support standard</span>
                                    </li>
                                </ul>
                                <button
                                    className={`${styles.planButton} ${styles.testButton}`}
                                >
                                    Commencer l'essai gratuit
                                </button>
                            </div>
                        </div>

                        {/* Plan Basique */}
                        <div className={styles.pricingCard}>
                            <div className={styles.pricingHeader}>
                                <div className={styles.iconContainer}>
                                    <FontAwesomeIcon
                                        icon={faBolt}
                                        className={`${styles.planIcon} ${styles.basicIcon}`}
                                    />
                                </div>
                                <h3 className={styles.planTitle}>Basique</h3>
                                <p className={styles.planSubtitle}>
                                    Pour les débutants
                                </p>
                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>
                                        {billingType === 'monthly'
                                            ? '29€'
                                            : billingType === 'quarterly'
                                              ? '27€'
                                              : '24€'}
                                    </span>
                                    <span className={styles.period}>/mois</span>
                                </div>
                                <p className={styles.billingInfo}>
                                    {billingType === 'monthly'
                                        ? 'Facturé mensuellement'
                                        : billingType === 'quarterly'
                                          ? 'Facturé 81€ / trimestre'
                                          : 'Facturé 288€ / an'}
                                </p>
                            </div>
                            <div className={styles.pricingBody}>
                                <ul className={styles.featuresList}>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>5 RDV inclus</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>10% commission / RDV supp.</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>5 Go de stockage</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                        <span
                                            className={styles.featureExcluded}
                                        >
                                            Automatisation documents
                                        </span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>Support standard</span>
                                    </li>
                                </ul>
                                <button
                                    className={`${styles.planButton} ${styles.basicButton}`}
                                    onClick={() => setSelectedPlan('basique')}
                                >
                                    Choisir ce forfait
                                </button>
                            </div>
                        </div>

                        {/* Plan Avancée */}
                        <div
                            className={`${styles.pricingCard} ${styles.featuredCard}`}
                        >
                            <div className={styles.featuredBadge}>
                                RECOMMANDÉ
                            </div>
                            <div className={styles.pricingHeader}>
                                <div className={styles.iconContainer}>
                                    <FontAwesomeIcon
                                        icon={faRocket}
                                        className={`${styles.planIcon} ${styles.advancedIcon}`}
                                    />
                                </div>
                                <h3 className={styles.planTitle}>Avancée</h3>
                                <p className={styles.planSubtitle}>
                                    Pour un flux régulier
                                </p>
                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>
                                        {billingType === 'monthly'
                                            ? '69€'
                                            : billingType === 'quarterly'
                                              ? '65€'
                                              : '59€'}
                                    </span>
                                    <span className={styles.period}>/mois</span>
                                </div>
                                <p className={styles.billingInfo}>
                                    {billingType === 'monthly'
                                        ? 'Facturé mensuellement'
                                        : billingType === 'quarterly'
                                          ? 'Facturé 195€ / trimestre'
                                          : 'Facturé 708€ / an'}
                                </p>
                            </div>
                            <div className={styles.pricingBody}>
                                <ul className={styles.featuresList}>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>15 RDV inclus</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>5% commission / RDV supp.</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>20 Go de stockage</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>
                                            Automatisation documents (modèles
                                            simples)
                                        </span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>Support prioritaire</span>
                                    </li>
                                </ul>
                                <button
                                    className={`${styles.planButton} ${styles.advancedButton}`}
                                    onClick={() => setSelectedPlan('avancee')}
                                >
                                    Choisir ce forfait
                                </button>
                            </div>
                        </div>

                        {/* Plan Premium */}
                        <div className={styles.pricingCard}>
                            <div className={styles.pricingHeader}>
                                <div className={styles.iconContainer}>
                                    <FontAwesomeIcon
                                        icon={faDiamond}
                                        className={`${styles.planIcon} ${styles.premiumIcon}`}
                                    />
                                </div>
                                <h3 className={styles.planTitle}>Premium</h3>
                                <p className={styles.planSubtitle}>
                                    Pour les gros volumes
                                </p>
                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>
                                        {billingType === 'monthly'
                                            ? '149€'
                                            : billingType === 'quarterly'
                                              ? '142€'
                                              : '129€'}
                                    </span>
                                    <span className={styles.period}>/mois</span>
                                </div>
                                <p className={styles.billingInfo}>
                                    {billingType === 'monthly'
                                        ? 'Facturé mensuellement'
                                        : billingType === 'quarterly'
                                          ? 'Facturé 426€ / trimestre'
                                          : 'Facturé 1 548€ / an'}
                                </p>
                            </div>
                            <div className={styles.pricingBody}>
                                <ul className={styles.featuresList}>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>RDV illimités</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>0% commission</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>100 Go de stockage</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>
                                            Automatisation avancée (IA +
                                            personnalisable)
                                        </span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>
                                            Support VIP (assistance dédiée)
                                        </span>
                                    </li>
                                </ul>
                                <button
                                    className={`${styles.planButton} ${styles.premiumButton}`}
                                    onClick={() => setSelectedPlan('premium')}
                                >
                                    Choisir ce forfait
                                </button>
                            </div>
                        </div>

                        {/* Plan Paiement à l'acte */}
                        <div className={styles.pricingCard}>
                            <div className={styles.pricingHeader}>
                                <div className={styles.iconContainer}>
                                    <FontAwesomeIcon
                                        icon={faMoneyBill}
                                        className={`${styles.planIcon} ${styles.payPerUseIcon}`}
                                    />
                                </div>
                                <h3 className={styles.planTitle}>
                                    Paiement à l'acte
                                </h3>
                                <p className={styles.planSubtitle}>
                                    Pour les occasionnels
                                </p>
                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>40€</span>
                                    <span className={styles.period}>/RDV</span>
                                </div>
                                <p className={styles.billingInfo}>
                                    Sans abonnement
                                </p>
                            </div>
                            <div className={styles.pricingBody}>
                                <ul className={styles.featuresList}>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                        <span
                                            className={styles.featureExcluded}
                                        >
                                            0 RDV inclus
                                        </span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>40€ par RDV</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>2 Go de stockage</span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                        <span
                                            className={styles.featureExcluded}
                                        >
                                            Automatisation documents
                                        </span>
                                    </li>
                                    <li className={styles.featureItem}>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={
                                                styles.featureIconIncluded
                                            }
                                        />
                                        <span>Support standard</span>
                                    </li>
                                </ul>
                                <button
                                    className={`${styles.planButton} ${styles.payPerUseButton}`}
                                    onClick={() => setSelectedPlan('acte')}
                                >
                                    Choisir ce forfait
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Custom Plan */}
                    <div className={styles.customPlan}>
                        <h3 className={styles.customPlanTitle}>
                            Besoin d'une solution sur mesure ?
                        </h3>
                        <p className={styles.customPlanText}>
                            Vous avez des besoins spécifiques ou vous êtes un
                            grand cabinet avec des exigences particulières ?
                            Contactez-nous pour une offre personnalisée adaptée
                            à votre structure.
                        </p>
                        <button className={styles.customPlanButton}>
                            Demander un devis personnalisé
                        </button>
                    </div>
                </div>
            </section>

            {/* Simulateur */}
            <section className={`${styles.section} ${styles.simulatorSection}`}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        <FontAwesomeIcon
                            icon={faCalculator}
                            className={styles.titleIcon}
                        />
                        Simulateur de coûts
                    </h2>
                    <p className={styles.simulatorIntro}>
                        Estimez vos coûts en fonction de votre volume de
                        rendez-vous et choisissez l'offre la plus adaptée à vos
                        besoins.
                    </p>

                    <div className={styles.simulatorCard}>
                        <div className={styles.simulatorGrid}>
                            {/* Paramètres du simulateur */}
                            <div className={styles.simulatorParams}>
                                <h3 className={styles.simulatorSubtitle}>
                                    Paramètres
                                </h3>

                                <div className={styles.paramGroup}>
                                    <label className={styles.paramLabel}>
                                        Type d'abonnement
                                    </label>
                                    <div className={styles.planButtonsGrid}>
                                        <button
                                            className={`${styles.planTypeButton} ${selectedPlan === 'basique' ? styles.basicActive : ''}`}
                                            onClick={() =>
                                                setSelectedPlan('basique')
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faBolt}
                                                className={styles.buttonIcon}
                                            />
                                            Basique
                                        </button>
                                        <button
                                            className={`${styles.planTypeButton} ${selectedPlan === 'avancee' ? styles.advancedActive : ''}`}
                                            onClick={() =>
                                                setSelectedPlan('avancee')
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faRocket}
                                                className={styles.buttonIcon}
                                            />
                                            Avancée
                                        </button>
                                        <button
                                            className={`${styles.planTypeButton} ${selectedPlan === 'premium' ? styles.premiumActive : ''}`}
                                            onClick={() =>
                                                setSelectedPlan('premium')
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faDiamond}
                                                className={styles.buttonIcon}
                                            />
                                            Premium
                                        </button>
                                        <button
                                            className={`${styles.planTypeButton} ${selectedPlan === 'acte' ? styles.payPerUseActive : ''}`}
                                            onClick={() =>
                                                setSelectedPlan('acte')
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faMoneyBill}
                                                className={styles.buttonIcon}
                                            />
                                            À l'acte
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.paramGroup}>
                                    <label className={styles.paramLabel}>
                                        Facturation
                                    </label>
                                    <div className={styles.billingButtonsGrid}>
                                        <button
                                            className={`${styles.billingTypeButton} ${billingType === 'monthly' ? styles.active : ''}`}
                                            onClick={() =>
                                                setBillingType('monthly')
                                            }
                                        >
                                            Mensuelle
                                        </button>
                                        <button
                                            className={`${styles.billingTypeButton} ${billingType === 'quarterly' ? styles.active : ''}`}
                                            onClick={() =>
                                                setBillingType('quarterly')
                                            }
                                        >
                                            Trimestrielle
                                        </button>
                                        <button
                                            className={`${styles.billingTypeButton} ${billingType === 'yearly' ? styles.active : ''}`}
                                            onClick={() =>
                                                setBillingType('yearly')
                                            }
                                        >
                                            Annuelle
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.paramGroup}>
                                    <label
                                        htmlFor="appointmentCount"
                                        className={styles.paramLabel}
                                    >
                                        Nombre de rendez-vous par mois
                                    </label>
                                    <input
                                        type="number"
                                        id="appointmentCount"
                                        min="1"
                                        value={appointmentCount}
                                        onChange={(e) =>
                                            setAppointmentCount(
                                                Number.parseInt(
                                                    e.target.value
                                                ) || 0
                                            )
                                        }
                                        className={styles.inputField}
                                    />
                                </div>

                                <div className={styles.paramGroup}>
                                    <label
                                        htmlFor="appointmentPrice"
                                        className={styles.paramLabel}
                                    >
                                        Prix moyen d'un rendez-vous (€)
                                    </label>
                                    <input
                                        type="number"
                                        id="appointmentPrice"
                                        min="1"
                                        value={appointmentPrice}
                                        onChange={(e) =>
                                            setAppointmentPrice(
                                                Number.parseInt(
                                                    e.target.value
                                                ) || 0
                                            )
                                        }
                                        className={styles.inputField}
                                    />
                                </div>
                            </div>

                            {/* Résultats du simulateur */}
                            <div className={styles.simulatorResults}>
                                <h3 className={styles.simulatorSubtitle}>
                                    Résultats
                                </h3>

                                <div className={styles.resultsContainer}>
                                    <div className={styles.resultRow}>
                                        <span className={styles.resultLabel}>
                                            Coût de l'abonnement :
                                        </span>
                                        <span className={styles.resultValue}>
                                            {simulationResult.subscriptionCost.toFixed(
                                                2
                                            )}
                                            €
                                        </span>
                                    </div>

                                    <div className={styles.resultRow}>
                                        <span className={styles.resultLabel}>
                                            Coût des rendez-vous supplémentaires
                                            :
                                        </span>
                                        <span className={styles.resultValue}>
                                            {simulationResult.appointmentCost.toFixed(
                                                2
                                            )}
                                            €
                                        </span>
                                    </div>

                                    <div
                                        className={`${styles.resultRow} ${styles.totalRow}`}
                                    >
                                        <span className={styles.totalLabel}>
                                            Coût total :
                                        </span>
                                        <span className={styles.totalValue}>
                                            {simulationResult.totalCost.toFixed(
                                                2
                                            )}
                                            €
                                        </span>
                                    </div>

                                    {simulationResult.savings > 0 && (
                                        <div className={styles.savingsBox}>
                                            <div className={styles.savingsRow}>
                                                <span
                                                    className={
                                                        styles.savingsLabel
                                                    }
                                                >
                                                    Économies par rapport au
                                                    paiement à l'acte :
                                                </span>
                                                <span
                                                    className={
                                                        styles.savingsValue
                                                    }
                                                >
                                                    {simulationResult.savings.toFixed(
                                                        2
                                                    )}
                                                    €
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {simulationResult.savings < 0 && (
                                        <div className={styles.extraCostBox}>
                                            <div
                                                className={styles.extraCostRow}
                                            >
                                                <span
                                                    className={
                                                        styles.extraCostLabel
                                                    }
                                                >
                                                    Surcoût par rapport au
                                                    paiement à l'acte :
                                                </span>
                                                <span
                                                    className={
                                                        styles.extraCostValue
                                                    }
                                                >
                                                    {Math.abs(
                                                        simulationResult.savings
                                                    ).toFixed(2)}
                                                    €
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className={styles.disclaimer}>
                                        <p>
                                            * Ces calculs sont donnés à titre
                                            indicatif et peuvent varier en
                                            fonction de votre utilisation
                                            réelle.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Features Comparison */}
            <section
                className={`${styles.section} ${styles.comparisonSection}`}
            >
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        Comparaison détaillée des fonctionnalités
                    </h2>

                    <div className={styles.tableContainer}>
                        <table className={styles.comparisonTable}>
                            <thead>
                                <tr>
                                    <th className={styles.featureColumn}>
                                        Fonctionnalité
                                    </th>
                                    <th>Test Gratuit</th>
                                    <th>Basique</th>
                                    <th className={styles.featuredColumn}>
                                        Avancée
                                    </th>
                                    <th>Premium</th>
                                    <th>À l'acte</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.featureColumn}>
                                        RDV inclus
                                    </td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td className={styles.featuredColumn}>
                                        15
                                    </td>
                                    <td>Illimités</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td className={styles.featureColumn}>
                                        Commission RDV supp.
                                    </td>
                                    <td>N/A</td>
                                    <td>10%</td>
                                    <td className={styles.featuredColumn}>
                                        5%
                                    </td>
                                    <td>0%</td>
                                    <td>40€/RDV</td>
                                </tr>
                                <tr>
                                    <td className={styles.featureColumn}>
                                        Stockage
                                    </td>
                                    <td>1 Go</td>
                                    <td>5 Go</td>
                                    <td className={styles.featuredColumn}>
                                        20 Go
                                    </td>
                                    <td>100 Go</td>
                                    <td>2 Go</td>
                                </tr>
                                <tr>
                                    <td className={styles.featureColumn}>
                                        Automatisation documents
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                    </td>
                                    <td>Modèles simples</td>
                                    <td className={styles.featuredColumn}>
                                        IA + personnalisable
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                styles.featureIconExcluded
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.featureColumn}>
                                        Support client
                                    </td>
                                    <td>Standard</td>
                                    <td>Standard</td>
                                    <td className={styles.featuredColumn}>
                                        Prioritaire
                                    </td>
                                    <td>VIP (dédié)</td>
                                    <td>Standard</td>
                                </tr>
                                <tr>
                                    <td className={styles.featureColumn}>
                                        Idéal pour
                                    </td>
                                    <td>Découverte</td>
                                    <td>Débutants, peu de RDV</td>
                                    <td className={styles.featuredColumn}>
                                        Flux régulier
                                    </td>
                                    <td>Gros volume</td>
                                    <td>Occasionnels</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            {/* FAQ */}
            <section className={`${styles.section} ${styles.faqSection}`}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        Questions fréquentes
                    </h2>

                    <div className={styles.faqContainer}>
                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Puis-je changer de forfait à tout moment ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Oui, vous pouvez passer à un forfait supérieur à
                                tout moment. Le changement prend effet
                                immédiatement et la différence est calculée au
                                prorata. Pour passer à un forfait inférieur, le
                                changement prendra effet à la fin de votre
                                période de facturation en cours.
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Comment fonctionne l'essai gratuit ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                L'essai gratuit vous donne accès à toutes les
                                fonctionnalités de base pendant 14 à 30 jours,
                                avec 3 rendez-vous offerts. Aucune carte
                                bancaire n'est requise pour commencer. À la fin
                                de la période d'essai, vous pourrez choisir le
                                forfait qui vous convient.
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Comment sont calculées les commissions sur les
                                rendez-vous ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Les commissions s'appliquent uniquement aux
                                rendez-vous supplémentaires, au-delà du nombre
                                inclus dans votre forfait. Le pourcentage de
                                commission est appliqué sur le prix du
                                rendez-vous. Par exemple, avec le forfait
                                Basique, pour un rendez-vous à 100€ au-delà des
                                5 inclus, la commission sera de 10€ (10%).
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Quels sont les moyens de paiement acceptés ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Nous acceptons les paiements par carte bancaire
                                (Visa, Mastercard, American Express) et par
                                prélèvement SEPA. Pour les cabinets optant pour
                                le forfait Premium, nous proposons également le
                                paiement par virement bancaire.
                            </p>
                        </div>

                        <div className={styles.faqItem}>
                            <h3 className={styles.faqQuestion}>
                                Proposez-vous des réductions pour les jeunes
                                avocats ?
                            </h3>
                            <p className={styles.faqAnswer}>
                                Oui, nous proposons une réduction de 20% sur
                                tous nos forfaits pendant la première année pour
                                les avocats inscrits au barreau depuis moins de
                                2 ans. Contactez notre service commercial pour
                                en bénéficier.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className={styles.ctaSection}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.ctaTitle}>
                        Prêt à transformer votre pratique juridique ?
                    </h2>
                    <p className={styles.ctaText}>
                        Rejoignez les milliers d'avocats qui utilisent déjà
                        Liber'All pour simplifier leur quotidien et améliorer
                        leur relation client.
                    </p>
                    <div className={styles.ctaButtons}>
                        <button className={styles.ctaButtonWhite}>
                            Essai gratuit
                        </button>
                        <button className={styles.ctaButtonOrange}>
                            Demander une démo
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
}
