import { ChangeEvent } from 'react';
import Input from '../../Input';
import Select from '../../Select';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    data?: any;
    isRequired?: boolean;
    label?: string;
}

function IdentityForm(props: Props) {
    const { onChange, data, isRequired, label } = props;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const labelName = label + ' ' + event.target.name;
            event.target.name = labelName;
            onChange(event);
        }
    };
    return (
        <div className={styles.container}>
            {label && <label className={styles.label}>{label}</label>}
            <div className={styles.identity}>
                <div className={styles.identityCivility}>
                    <Select
                        name="civility"
                        label={'Civilité'}
                        placeholder="Civilité"
                        value={data?.civility}
                        options={[
                            { name: 'Monsieur', label: 'Monsieur' },
                            { name: 'Madame', label: 'Madame' },
                        ]}
                        onChange={handleChange}
                        isRequired={isRequired}
                    />
                </div>
                <div className={styles.identityName}>
                    <Input
                        name={'lastName'}
                        label={'Nom'}
                        type="text"
                        value={data?.lastName}
                        onChange={handleChange}
                        isRequired={isRequired}
                    />
                </div>
                <div className={styles.identityFirstname}>
                    <Input
                        name={'firstName'}
                        label={'Prénom'}
                        type="text"
                        value={data?.firstName}
                        onChange={handleChange}
                        isRequired={isRequired}
                    />
                </div>
            </div>
        </div>
    );
}

export default IdentityForm;
