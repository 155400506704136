import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import Checkbox from '../../../../components/Checkbox';
import LoaderDot from '../../../../components/LoaderDot';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import OptionInterface from '../../../../interfaces/OptionInterface';
import {
    AddMyWidget,
    fetchMyWidgets,
    fetchWidgets,
} from '../../../../services/widgetService';
import WidgetType from '../../../../types/WidgetType';

function WidgetsSettings() {
    const [widgetList, setWidgetList] = useState([]);
    const alert = useAlert();
    const { isPending: isWidgetPending, data: widgets } = useQuery({
        queryKey: ['widgets'],
        queryFn: async () => {
            try {
                const { data } = await fetchWidgets();
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });
    const { isPending: isMyWidgetPending } = useQuery({
        queryKey: ['myWidgets'],
        queryFn: async () => {
            try {
                const { data } = await fetchMyWidgets();
                setWidgetList(data);
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const { mutate } = useMutation({
        mutationFn: async (id: string) => {
            if (id?.length > 0) {
                const { data, status } = await AddMyWidget(id);
                setWidgetList(data);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
            }
        },
    });

    const widgetsOptions: OptionInterface[] = widgets?.map(
        (widget: WidgetType) => {
            return {
                label: widget.label,
                name: widget.id,
            };
        }
    );
    const myWidgetsOptions: OptionInterface[] = widgetList?.map(
        (widget: WidgetType) => {
            return {
                label: widget.label,
                name: widget.id,
            };
        }
    );

    const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        mutate(value);
    };

    if (isWidgetPending || isMyWidgetPending) {
        <LoaderDot />;
    }
    return (
        <Checkbox
            name="widget"
            options={widgetsOptions}
            checked={myWidgetsOptions}
            onChange={handleChangeCheckbox}
        />
    );
}

export default WidgetsSettings;
