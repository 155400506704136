import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    data?: any;
    label?: string;
}

function EmailForm(props: Props) {
    const { onChange, data, label } = props;

    return (
        <div className={styles.container}>
            {label && <label className={styles.label}>{label}</label>}
            <div className={styles.email}>
                <div className={styles.input}>
                    <Input
                        name={'email'}
                        label="Email"
                        type="email"
                        value={data?.email}
                        onChange={onChange}
                    />
                </div>
                <div className={styles.input}>
                    <Input
                        name={'confirmEmail'}
                        label="Confirmation email"
                        type="email"
                        value={data?.confirmEmail}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default EmailForm;
