import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import ButtonLink from '../../../components/ButtonLink';
import Divider from '../../../components/Divider';
import Loader from '../../../components/Loader';
import SearchInput from '../../../components/SearchInput';
import {
    formsCreatePage,
    formsEditPage,
} from '../../../constants/pagesRoutesContants';
import { fetchForms } from '../../../services/formService';
import FormType from '../../../types/FormType';
import styles from './style.module.scss';

function FormsEditor() {
    const [forms, setForms] = useState<FormType[]>([]);

    const { isPending: isPendingForms, data } = useQuery({
        queryKey: ['forms'],
        queryFn: async () => {
            try {
                const { data } = await fetchForms();
                setForms(data || []);
                return data;
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const handleSearchForm = (event: ChangeEvent<HTMLInputElement>) => {
        const { value = '' } = event.target;
        const searchedCase = data.filter((x: FormType) => {
            return x.name
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase());
        });
        setForms(searchedCase);
    };

    if (isPendingForms) {
        return <Loader />;
    }

    return (
        <div className={styles.container}>
            <h1>Formulaires</h1>
            <Divider />
            <div className={styles.forms}>
                <div className={styles.header}>
                    <SearchInput onChange={handleSearchForm} />
                    <div>
                        <ButtonLink link={formsCreatePage.path}>
                            Créer un formulaire
                        </ButtonLink>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Date de création</th>
                            <th scope="col">
                                Date de la dernière modification
                            </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody className={styles.events}>
                        {forms?.map((form) => (
                            <tr className={styles.event} key={form.id}>
                                <td>{form.name}</td>
                                <td>
                                    {new Date(
                                        form.creationDate
                                    ).toLocaleString()}
                                </td>
                                <td>
                                    {form.editDate &&
                                        new Date(
                                            form.editDate
                                        ).toLocaleString()}
                                </td>
                                <td>
                                    {form.id && (
                                        <ButtonLink
                                            link={formsEditPage.path + form.id}
                                        >
                                            Ouvrir
                                        </ButtonLink>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FormsEditor;
