import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import LoaderDot from '../../../../../../components/LoaderDot';
import ModalAction from '../../../../../../components/ModalAction';
import Select from '../../../../../../components/Select';
import OptionInterface from '../../../../../../interfaces/OptionInterface';
import { sendForm } from '../../../../../../services/formResponseService';
import { fetchForms } from '../../../../../../services/formService';
import CaseType from '../../../../../../types/CaseType';
import FormElementType from '../../../../../../types/FormElementType';
import styles from './style.module.scss';

interface Props {
    setModalOpened: (param: boolean) => void;
    modalOpened: boolean;
    clientCase: CaseType;
}

function ClientCaseFormModal(props: Props) {
    const { setModalOpened, modalOpened, clientCase } = props;

    const [selectedForm, setSelectedForm] = useState('');

    const {
        mutate: getForms,
        isPending: isFormsPending,
        data: forms,
    } = useMutation({
        mutationFn: async () => {
            const { data } = await fetchForms();
            return data || [];
        },
    });

    useEffect(() => {
        if (modalOpened) {
            getForms();
        }
    }, [modalOpened, getForms]);

    const { mutate: handleSendForm } = useMutation({
        mutationFn: async () => {
            console.log('selectedForm', selectedForm);
            if (selectedForm.length > 0) {
                const { data } = await sendForm({
                    clientCaseId: clientCase.id,
                    userId: clientCase.userId,
                    formId: selectedForm,
                });
                return data || [];
            }
        },
    });

    const handleCloseModal = () => {
        setSelectedForm('');
    };

    const handleSelectForm = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setSelectedForm(value);
    };

    const selectOptions: OptionInterface[] = forms?.map(
        (form: FormElementType) => {
            return {
                data: form,
                id: form.id,
                name: form.name,
                label: form.name,
            };
        }
    );

    if (isFormsPending) {
        return <LoaderDot />;
    }

    return (
        <ModalAction
            setOpened={setModalOpened}
            opened={modalOpened}
            onClose={handleCloseModal}
            onClick={handleSendForm}
            actionLabel="Envoyer"
            isDisabled={selectedForm.length === 0}
        >
            <h1>Formulaires</h1>
            <div className={styles.modalBody}>
                <Select
                    label="Selectionnez le formulaire à envoyer"
                    name="forms"
                    options={selectOptions}
                    onChange={handleSelectForm}
                />
            </div>
        </ModalAction>
    );
}

export default ClientCaseFormModal;
