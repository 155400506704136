import { ChangeEvent } from 'react';
import Input from '../../../../../components/Input';
import styles from './style.module.scss';

interface Props {
    formName: string;
    setFormName: (param: string) => void;
    formDescription: string;
    setFormDescription: (param: string) => void;
}

function NameDescriptionForm(props: Props) {
    const { formName, setFormName, formDescription, setFormDescription } =
        props;

    const handleChangeFormName = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFormName(value);
    };
    const handleChangeFormDescription = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        setFormDescription(value);
    };

    return (
        <div className={styles.inputContainer}>
            <Input
                onChange={handleChangeFormName}
                value={formName}
                label="Nom du formulaire"
                error={
                    formName.length === 0
                        ? 'Veuillez donner un nom à votre formulaire'
                        : null
                }
            />
            <Input
                onChange={handleChangeFormDescription}
                value={formDescription}
                label="Description"
            />
        </div>
    );
}

export default NameDescriptionForm;
