import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Loader from '../../../../../components/Loader';
import { fetchFormsResponsesByClientCaseId } from '../../../../../services/formResponseService';
import CaseType from '../../../../../types/CaseType';
import CaseFormModal from './caseFormModal';
import CaseFormsResponsesTable from './caseFormResponsesTable';
import styles from './style.module.scss';

interface Props {
    clientCase: CaseType;
}

function CaseFormResponses(props: Props) {
    const { clientCase } = props;
    const [modalOpened, setModalOpened] = useState(false);

    const { isPending: isPendingForms, data: formResponses } = useQuery({
        queryKey: ['formsResponses'],
        queryFn: async () => {
            try {
                const { data } = await fetchFormsResponsesByClientCaseId(
                    clientCase.id
                );
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Formulaires:</span>
            </h2>
            <CaseFormModal
                modalOpened={modalOpened}
                setModalOpened={setModalOpened}
                clientCase={clientCase}
            />
            <div className={styles.table}>
                {isPendingForms ? (
                    <Loader />
                ) : (
                    formResponses.length > 0 && (
                        <CaseFormsResponsesTable
                            formResponses={formResponses}
                        />
                    )
                )}
            </div>
        </div>
    );
}

export default CaseFormResponses;
