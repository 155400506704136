import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { storageItems } from '../../../constants/storageItemsContants';
import OptionInterface from '../../../interfaces/OptionInterface';
import { fetchLawfirmsByName } from '../../../services/lawfirmService';
import LawfirmType from '../../../types/LawfirmType';
import Autocomplete from '../../Autocomplete';

interface Props {
    onOptionSelected: (value: LawfirmType | null) => any;
    onChange?: (value: any) => any;
}

function SearchByLawfirm(props: Props) {
    const { onOptionSelected, onChange } = props;

    const [lawfirmValue, setLawfirmValue] = useState(
        localStorage.getItem(storageItems.lawfirm) || ''
    );

    const { isPending, data: lawfirms } = useQuery({
        queryKey: ['lawfirms', { search: lawfirmValue }],
        queryFn: async () => {
            try {
                if (lawfirmValue.length === 0) {
                    return [];
                }
                const { data } = await fetchLawfirmsByName(lawfirmValue);
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const lawfirmsOptions: OptionInterface[] = lawfirms?.map(
        (lawfirm: LawfirmType) => {
            return {
                id: lawfirm.id,
                name: lawfirm.name,
                label: lawfirm.name,
                data: lawfirm,
            };
        }
    );

    const handleChangeLawfirm = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLawfirmValue(value);
        if (onChange) {
            onChange(event);
        }
    };

    const handleOptionClick = (option?: OptionInterface) => {
        onOptionSelected(option?.data);
    };

    return (
        <Autocomplete
            options={lawfirmsOptions}
            label="Cabinet"
            onChange={handleChangeLawfirm}
            onOptionClick={handleOptionClick}
            value={lawfirmValue}
            name="lawfirm"
            isDataLoading={isPending}
        />
    );
}

export default SearchByLawfirm;
