/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { formElements } from '../../constants/formContants';
import CreateFormResponseFieldInterface from '../../interfaces/CreateFormResponseFieldInterface';
import OptionInterface from '../../interfaces/OptionInterface';
import FormElementType from '../../types/FormElementType';
import Element from '../Element';
import styles from './style.module.scss';

interface Props {
    form: FormElementType[];
    setFormResponsesFields: (param: CreateFormResponseFieldInterface[]) => void;
    formResponsesFieds: CreateFormResponseFieldInterface[];
}

function DynamicFormElements(props: Props) {
    const { form, setFormResponsesFields, formResponsesFieds } = props;
    const [values, setValues] = useState<{ [key: string]: string | number }>(
        {}
    );

    const handleOnChange = (event: any, data: any) => {
        const { value, name, type, label } = event.target;

        const newValues = { ...values };
        newValues[name] = type === 'checkbox' ? data : value;
        setValues(newValues);

        console.log(value, name, type, label);

        const field = form.find((element) => {
            console.log('element =>', element);
            console.log('name =>', name);
            Object.keys(formElements).forEach((key) => {
                if (formElements[key].fields.includes(name)) {
                    return element.formComponentElement === key;
                }
            });

            return element.name === name;
        });

        console.log('name =>', name);
        console.log('field =>', field);

        const newField: CreateFormResponseFieldInterface = {
            name,
            value:
                type === 'checkbox'
                    ? data.map((item: OptionInterface) => {
                          return item.label;
                      })
                    : [value],
            label: field?.label || label,
            isRequired: field?.isRequired || false,
        };

        const newListFields = [...formResponsesFieds];
        const existedField = newListFields.find((field) => {
            return field.name === name;
        });
        if (existedField) {
            newListFields[newListFields.indexOf(existedField)] = newField;
        } else {
            newListFields.push(newField);
        }

        setFormResponsesFields(newListFields);
    };

    return (
        <div className={styles.container}>
            {form
                .sort((a, b) => {
                    return a.position - b.position;
                })
                .map((element) => {
                    return (
                        <Element
                            key={element.name}
                            element={element}
                            onChange={handleOnChange}
                            value={values[element.name]}
                        />
                    );
                })}
        </div>
    );
}
export default DynamicFormElements;
