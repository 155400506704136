import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../../components/Button';
import { GetTimeGapString } from '../../../../helpers/date';
import { fetchMyMeetings } from '../../../../services/meetingService';
import MeetingType from '../../../../types/MeetingDateType';
import styles from './style.module.scss';

interface Props {
    userId: string;
}

function MeetingsTable(props: Props) {
    const { userId } = props;
    // ajouter une page de détails des étapes du dossier

    const { data: meetings } = useQuery({
        queryKey: ['meeting'],
        queryFn: async () => {
            const { data } = await fetchMyMeetings();
            return data;
        },
    });

    const handleClickOpenCase = () => {
        console.log(userId);
    };
    // - voir les rdv a venir et passé avec ce client
    // - voir s'il y a une facture pour le rdv et si cela à été payé
    return (
        <div className={styles.container}>
            <table>
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Dossier</th>
                        <th scope="col">Type rendez-vous</th>
                        <th scope="col">Sujet</th>
                        <th scope="col">Durée</th>
                        <th scope="col">Date de création</th>
                        <th scope="col">status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {meetings?.map((event: MeetingType) => (
                        <tr className={styles.event} key={event.id}>
                            <td className={styles.iconCol}>
                                <FontAwesomeIcon icon={faCalendarDay} />
                            </td>
                            <td>{event.clientCaseId}</td>
                            <td>{event.title}</td>
                            <td>{event.subject}</td>
                            <td>{GetTimeGapString(event.start, event.end)}</td>
                            <td>{new Date(event.start).toLocaleString()}</td>
                            <td>{'A venir / Passé'}</td>
                            <td>
                                <Button onClick={handleClickOpenCase}>
                                    Voir la facture
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MeetingsTable;
