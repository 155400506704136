// import styles from "./style.module.scss";
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import human_location from '../../../assets/human-location.svg';
import { initialCoordonates } from '../../../constants/mapContants';
import AddressType from '../../../types/AddressType';

interface Props {
    userLocation: AddressType;
}

function LocationMarker(props: Props) {
    const map = useMap();
    const { userLocation } = props;

    const myIcon = new Icon({
        iconUrl: human_location,
        iconSize: [32, 32],
    });

    useEffect(() => {
        if (userLocation.latitude && userLocation.longitude) {
            map.flyTo(
                [userLocation.latitude, userLocation.longitude],
                userLocation.zoom || 14,
                {
                    animate:
                        userLocation.animated === false
                            ? userLocation.animated
                            : true,
                }
            );
        }
    }, [userLocation, map]);

    return !userLocation.latitude ||
        !userLocation.longitude ||
        userLocation.address === initialCoordonates.address ? null : (
        <Marker
            position={[userLocation.latitude, userLocation.longitude]}
            icon={myIcon}
        >
            <Popup>You are here</Popup>
        </Marker>
    );
}

export default LocationMarker;
