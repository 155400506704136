import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideNav from '../../../components/SideNav';
import Toolbar from '../../../components/Toolbar';
import NavDataInterface from '../../../interfaces/NavDataInterface';
import { fetchContactClient } from '../../../services/contactClientService';
import UserType from '../../../types/UserType';
import ClientDetails from './clientDetails';
import styles from './style.module.scss';

function Clients() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const contactId = searchParams.get('id') || '';

    const { data: contacts } = useQuery({
        queryKey: ['contacts'],
        queryFn: async () => {
            try {
                const { data } = await fetchContactClient();
                return data || [];
            } catch (error) {
                console.log(error);
            }
            return [];
        },
    });

    const navData: NavDataInterface[] = contacts?.map(
        (contact: UserType): NavDataInterface => {
            return {
                id: contact.id || '',
                label: `${contact.firstName} ${contact.lastName}`,
                link: '?id=' + contact.id,
                variant: 'tertiary',
                icon: 'fa-user',
                iconColor: '#ffffff',
            };
        }
    );
    const defaultToolbar: NavDataInterface[] = [];
    const [toolbar, setToolbar] = useState<NavDataInterface[]>();

    return (
        <div className={styles.container}>
            <SideNav
                selected=""
                navData={navData}
                extendable={contactId.length > 0}
                hasSearchBar={true}
                searchBarLabel="Contacts :"
                reduceIcon={faUser}
            />
            <div className={styles.body}>
                <Toolbar
                    toolbarData={
                        contactId.length > 0 ? toolbar : defaultToolbar
                    }
                    isSticky={contactId.length > 0}
                />
                {contactId.length > 0 && (
                    <ClientDetails setToolbar={setToolbar} userId={contactId} />
                )}
            </div>
        </div>
    );
}

export default Clients;
