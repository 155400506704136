import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthProvider';
import {
    dashboardPage,
    featuresPage,
    lawfirmsPage,
    lawyersPage,
    liberallContactPage,
    loginPage,
    offersPage,
    pricingPage,
    registerPage,
} from '../../../constants/pagesRoutesContants';
import Role from '../../../enums/RoleEnum';
import styles from './style.module.scss';

function Header() {
    const { isAuth, role } = useAuth();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <header className={styles.header}>
            <div className={styles.headerContent}>
                <div className={styles.logo}>
                    <Link to="/" reloadDocument className={styles.logoLink}>
                        <img
                            src={'/logo-192x192.png'}
                            alt="Liber'All Logo"
                            width={60}
                            height={60}
                            className={styles.logoImage}
                        />
                        <div className={styles.logoText}>
                            <h1>Liber'All</h1>
                            <span>Votre plateforme juridique</span>
                        </div>
                    </Link>
                </div>

                {/* Mobile menu button */}
                <button
                    className={styles.mobileMenuBtn}
                    onClick={toggleMobileMenu}
                    aria-label="Menu"
                >
                    <FontAwesomeIcon icon={mobileMenuOpen ? faXmark : faBars} />
                </button>

                {/* Navigation */}
                <nav
                    className={`${styles.mainNav} ${mobileMenuOpen ? styles.open : ''}`}
                >
                    <Link
                        to="/"
                        reloadDocument
                        className={styles.navLink}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Accueil
                    </Link>
                    <Link
                        to={lawyersPage.path}
                        className={styles.navLink}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        {lawyersPage.name}
                    </Link>
                    <Link
                        to={lawfirmsPage.path}
                        reloadDocument
                        className={styles.navLink}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        {lawfirmsPage.name}
                    </Link>
                    <Link
                        to={featuresPage.path}
                        reloadDocument
                        className={styles.navLink}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Fonctionnalités
                    </Link>
                    <Link
                        to={liberallContactPage.path}
                        reloadDocument
                        className={styles.navLink}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Contact
                    </Link>
                    {isAuth && role === Role.Administrator && (
                        <>
                            <Link to={pricingPage.path} reloadDocument>
                                {pricingPage.name}
                            </Link>
                            <Link to={offersPage.path} reloadDocument>
                                {offersPage.name}
                            </Link>
                        </>
                    )}

                    <div
                        className={`${styles.authButtons} ${mobileMenuOpen ? styles.open : ''}`}
                    >
                        {isAuth ? (
                            <Link
                                to={dashboardPage.path}
                                reloadDocument
                                className={`${styles.btn} ${styles.btnPrimary}`}
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                Mon Compte
                            </Link>
                        ) : (
                            <>
                                <Link
                                    to={loginPage.path}
                                    reloadDocument
                                    className={`${styles.btn} ${styles.btnOutline}`}
                                >
                                    Connexion
                                </Link>
                                <Link
                                    to={registerPage.path}
                                    reloadDocument
                                    className={`${styles.btn} ${styles.btnPrimary}`}
                                >
                                    Inscription
                                </Link>
                            </>
                        )}
                    </div>
                </nav>

                {/* <Link link={juristsPage.path}>Juristes</Link> */}
                {/* <Link link={informationPage.path}>Informations</Link> */}
            </div>
        </header>
    );
}

export default Header;
