import { useQuery } from '@tanstack/react-query';
import { Icon } from 'leaflet';
import { ChangeEvent, useState } from 'react';
import location from '../../assets/location.svg';
import Map from '../../components/Map';
import SearchBar from '../../components/SearchBar';
import { storageItems } from '../../constants/storageItemsContants';
import { classNames } from '../../helpers/classNames';
import MapDataInterface from '../../interfaces/MapDataInterface';
import { fetchLawfirms } from '../../services/lawfirmService';
import AddressType from '../../types/AddressType';
import LawfirmType from '../../types/LawfirmType';
import LawfirmCard from './lawfirmCard';
import LawfirmPopup from './lawfirmPopup';
import NoLawfirmFound from './noLawfirmFound';
import styles from './style.module.scss';

function Lawfirms() {
    const speciality =
        localStorage.getItem(storageItems.speciality) || undefined;
    const [params, setParams] = useState<{ [key: string]: string | undefined }>(
        {
            speciality: speciality,
        }
    );
    const [inputValue, setInputValue] = useState<{
        [key: string]: string | undefined;
    }>({});
    const [lawfirmsList, setLawfirmsList] = useState<LawfirmType[]>([]);

    const { isPending: isPendingLawfirms } = useQuery({
        queryKey: ['lawfirm', params],
        queryFn: async () => {
            try {
                const { data } = await fetchLawfirms(params);
                setLawfirmsList(data || []);
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const lawfirmIcon = new Icon({
        iconUrl: location,
        iconSize: [32, 32],
    });

    if (isPendingLawfirms && lawfirmsList.length < 1) {
        return <div>Loading...</div>;
    }

    const mapData: MapDataInterface[] = lawfirmsList.map(
        (lawfirm: LawfirmType) => {
            return {
                id: lawfirm.id,
                label: lawfirm.name,
                latitude: lawfirm.latitude,
                longitude: lawfirm.longitude,
                link: `#${lawfirm.id}`,
                icon: lawfirmIcon,
                popup: <LawfirmPopup lawfirm={lawfirm} />,
            };
        }
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newParams = { ...params };
        newParams[name] = '';
        if (value.length !== 0) {
            newParams[name] = value;
        } else {
            newParams[name] = undefined;
            setParams(newParams);
        }
        setInputValue(newParams);
    };

    const handleSetUserLocation = (data: AddressType) => {
        const newParam = { ...params };
        newParam['address'] = data.address;
        newParam['longitude'] = data.longitude?.toString();
        newParam['latitude'] = data.latitude?.toString();
        setParams(newParam);
    };

    const userLocation: AddressType = {
        address: params['address'],
        longitude: Number(params['longitude']),
        latitude: Number(params['latitude']),
    };

    return (
        <div className={classNames([styles.lawfirms])}>
            <div className={styles.map}>
                <Map
                    data={mapData}
                    userLocation={userLocation}
                    setUserLocation={handleSetUserLocation}
                />
            </div>
            <div className={styles.searchBarContainer}>
                <SearchBar
                    setParams={setParams}
                    params={inputValue}
                    onChange={handleChange}
                />
            </div>
            <div className={styles.listContainer}>
                {lawfirmsList.length > 0 ? (
                    lawfirmsList.map((lawfirm: LawfirmType) => (
                        <LawfirmCard key={lawfirm.id} lawfirm={lawfirm} />
                    ))
                ) : (
                    <NoLawfirmFound />
                )}
            </div>
        </div>
    );
}

export default Lawfirms;
