import { ChangeEvent, useEffect, useState } from 'react';
import { classNames } from '../../helpers/classNames';
import OptionInterface from '../../interfaces/OptionInterface';
import Button from '../Button';
import FaIcon from '../Icons/FaIcon';
import LoaderDot from '../LoaderDot';
import styles from './style.module.scss';

interface Props {
    name?: string;
    label?: string;
    type?: string;
    icon?: string;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
    onOptionClick?: (value?: OptionInterface) => unknown;
    onFocus?: (param: unknown) => unknown;
    onClick?: (param: unknown) => unknown;
    onBlur?: (param: unknown) => unknown;
    inputClassNames?: string;
    min?: number;
    max?: number;
    value?: string;
    isChecked?: boolean;
    options: OptionInterface[];
    isDataLoading?: boolean;
    reset?: boolean;
    disabled?: boolean;
}

function Autocomplete(props: Props) {
    const {
        name,
        label,
        type,
        icon,
        placeholder,
        onChange,
        onFocus,
        onClick,
        onBlur,
        isChecked,
        value = '',
        inputClassNames,
        options = [],
        onOptionClick,
        isDataLoading,
        reset = false,
        disabled = false,
    } = props;

    const [optionsToDisplay, setOptionsToDisplay] =
        useState<OptionInterface[]>(options);

    const [display, setDisplay] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
        const optionValue = options.find((x) => x.id === value);
        if (optionValue) {
            setInputValue(optionValue?.label);
        }

        if (options.length > 0) {
            setOptionsToDisplay(options);
        }
    }, [options, value]);

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value: searchValue } = event.target;
        setInputValue(searchValue);
        setOptionsToDisplay([]);
        const listOptions = options?.filter((option) => {
            return option.label
                ?.toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase());
        });
        const optionsToDisplay =
            listOptions?.length > 0 ? listOptions : options;
        setOptionsToDisplay(optionsToDisplay);
        if (onChange) {
            onChange(event);
        }
    };

    const onClickOption = (option: OptionInterface) => {
        if (option.label) {
            setInputValue(option.label);
        }
        if (onOptionClick) {
            onOptionClick(option);
        }
        if (reset) {
            setInputValue('');
        }
        setDisplay(false);
    };

    const handleClick = (event: unknown) => {
        if (onClick) {
            onClick(event);
        }
        setDisplay(true);
    };

    const handleOnFocus = (event: unknown) => {
        if (onFocus) {
            onFocus(event);
        }
    };
    const handleOnBlur = (event: unknown) => {
        if (onBlur) {
            onBlur(event);
        }
        setDisplay(false);
    };

    return (
        <div className={classNames([styles.divInput])}>
            <div className={styles.inputContainer}>
                {label && label !== '' && (
                    <>
                        <label className={classNames([styles.labelInput])}>
                            {icon && <FaIcon>{icon}</FaIcon>}
                            {label}
                        </label>
                    </>
                )}
                <input
                    name={name}
                    className={classNames([styles.input, inputClassNames])}
                    onChange={onInputChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    onClick={handleClick}
                    placeholder={placeholder}
                    type={type}
                    checked={isChecked}
                    value={inputValue}
                    disabled={disabled}
                />
                {isDataLoading && (
                    <div className={styles.loading}>
                        <LoaderDot />
                    </div>
                )}
            </div>
            {optionsToDisplay.length > 0 && display && (
                <div className={styles.list}>
                    <ul>
                        {optionsToDisplay?.map((option) => (
                            <li key={option.id}>
                                <Button
                                    variant="secondaryLight"
                                    onMouseDown={(event: MouseEvent) => {
                                        event.preventDefault();
                                        onClickOption(option);
                                    }}
                                >
                                    {option.label}
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Autocomplete;
