import { formEditorEndpoint as endpoint } from '../constants/endpointsContants';
import CreateFormType from '../interfaces/CreateFormInterface';
import request from './genericRequestService';

async function postForm(data: CreateFormType) {
    return await request.post({ endpoint, data });
}

async function fetchForms() {
    return await request.get({ endpoint });
}

async function fetchForm(id: string) {
    return await request.get({ endpoint, id });
}

async function updateForm(id: string, data: CreateFormType) {
    return await request.put({ endpoint, id, data });
}

async function deleteForm(id: string) {
    return await request.delete({ endpoint, id });
}

export { deleteForm, fetchForm, fetchForms, postForm, updateForm };
