import { ChangeEvent } from 'react';
import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    data?: any;
    label?: string;
}

function AddressForm(props: Props) {
    const { onChange, data, label } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const labelName = label + ' ' + event.target.name;
            event.target.name = labelName;
            onChange(event);
        }
    };

    return (
        <div className={styles.container}>
            {label && <label className={styles.label}>{label}</label>}
            <div className={styles.address}>
                <div className={styles.streetName}>
                    <Input
                        name="streetName"
                        label="Adresse"
                        type="text"
                        value={data?.streetName}
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.zipCode}>
                    <Input
                        name="zipCode"
                        label="Code Postal"
                        type="text"
                        value={data?.zipCode}
                        onChange={handleChange}
                        max={5}
                    />
                </div>
                <div className={styles.city}>
                    <Input
                        name="city"
                        label="Commune"
                        type="text"
                        value={data?.city}
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.country}>
                    <Input
                        name="country"
                        label="Pays"
                        type="text"
                        value={data?.country}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default AddressForm;
