import type React from 'react';
import { Link } from 'react-router-dom';
import {
    contactPage,
    featuresPage,
    offersPage,
} from '../../../constants/pagesRoutesContants';
import styles from './style.module.scss';

interface FooterProps {
    isMinimal?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isMinimal = false }) => {
    if (isMinimal) {
        return (
            <footer className={styles.footer}>
                <div className={styles.footerContent}>
                    <div className={styles.footerBottom}>
                        <p>
                            &copy; {new Date().getFullYear()} Liber'All. Tous
                            droits réservés.
                        </p>
                    </div>
                </div>
            </footer>
        );
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.footerGrid}>
                    <div className={styles.footerColumn}>
                        <h3>Liber'All</h3>
                        <p>
                            La plateforme qui simplifie la relation entre
                            avocats et clients.
                        </p>
                    </div>

                    <div className={styles.footerColumn}>
                        <h3>Liens rapides</h3>
                        <ul className={styles.footerLinks}>
                            <li>
                                <Link to="/" className={styles.footerLink}>
                                    Accueil
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={featuresPage.path}
                                    className={styles.footerLink}
                                >
                                    Fonctionnalités
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={offersPage.path}
                                    className={styles.footerLink}
                                >
                                    Offres
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={contactPage.path}
                                    className={styles.footerLink}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.footerColumn}>
                        <h3>Informations légales</h3>
                        <ul className={styles.footerLinks}>
                            <li>
                                <Link to="#" className={styles.footerLink}>
                                    Mentions légales
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className={styles.footerLink}>
                                    Politique de confidentialité
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className={styles.footerLink}>
                                    Conditions générales
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className={styles.footerLink}>
                                    Politique de cookies
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.footerColumn}>
                        <h3>Newsletter</h3>
                        <p>
                            Inscrivez-vous pour recevoir nos actualités
                            juridiques.
                        </p>
                        <form className={styles.newsletterForm}>
                            <input
                                type="email"
                                placeholder="Votre email"
                                className={styles.newsletterInput}
                            />
                            <button
                                type="submit"
                                className={styles.newsletterButton}
                            >
                                OK
                            </button>
                        </form>
                    </div>
                </div>

                <div className={styles.footerBottom}>
                    <p>
                        &copy; {new Date().getFullYear()} Liber'All. Tous droits
                        réservés.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
