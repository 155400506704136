import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '../../../../AlertProvider';
import { useAuth } from '../../../../AuthProvider';
import Button from '../../../../components/Button';
import FileExplorer from '../../../../components/FileExplorer/file-explorer';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import NotFound from '../../../../components/NotFound';
import Toolbar from '../../../../components/Toolbar';
import { alertType } from '../../../../constants/alertTypeConstant';
import {
    requestStatus,
    successStatus,
} from '../../../../constants/requestStatusContants';
import Action from '../../../../enums/ActionsEnum';
import Role from '../../../../enums/RoleEnum';
import LawyerToCaseInterface from '../../../../interfaces/LawyerToCaseInterface';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import {
    fetchClientCase,
    postCaseBookmark,
} from '../../../../services/caseService';
import {
    addLawyerToCase,
    rejectCase,
    transferCaseToLawyer,
} from '../../../../services/lawyerCaseService';
import CaseStepType from '../../../../types/CaseStepType';
import CaseType from '../../../../types/CaseType';
import LawyerType from '../../../../types/LawyerType';
import CaseDescription from './caseDescription';
import JudicialCalendar from './caseJudicialCalendar';
import CaseNotes from './caseNotes';
import PersonnalData from './casePersonnalData';
import CaseSteps from './caseSteps';
import CaseFormEditorResponses from './clientCaseFormResponses';
import SearchMyLawyer from './SearchMyLawyer';
import styles from './style.module.scss';

function ClientCaseDetails() {
    // ajouter une page de détails des étapes du dossier

    const alert = useAlert();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { caseId = '' } = useParams();
    const { pathname } = useLocation();
    const [addLawyerModalOpened, setAddLawyerModalOpened] = useState(false);
    const [lawyerValue, setLawyerValue] = useState<LawyerType | null>(null);
    const { data: clientCase, isPending } = useQuery({
        queryKey: ['getUserCase', caseId],
        queryFn: async () => {
            if (!caseId) {
                return null;
            }
            const { data } = await fetchClientCase(caseId);
            return data as CaseType;
        },
    });

    const { mutate: addLawyer } = useMutation({
        mutationFn: async (lawyerToCaseData: LawyerToCaseInterface) => {
            if (lawyerToCaseData) {
                const { data, status } =
                    await addLawyerToCase(lawyerToCaseData);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Votre demande a été envoyée avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const { mutate: transferLawyer } = useMutation({
        mutationFn: async (lawyerToCaseData: LawyerToCaseInterface) => {
            if (lawyerToCaseData) {
                const { data, status } =
                    await transferCaseToLawyer(lawyerToCaseData);

                if (successStatus.includes(status)) {
                    alert.show({
                        children: 'Votre demande a été envoyée avec succès',
                        type: alertType[status],
                        onClose: () => {
                            navigate(pathname);
                        },
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const [action, setAction] = useState<Action.add | Action.edit | null>();
    const actions = {
        [Action.add]: { request: addLawyer },
        [Action.edit]: { request: transferLawyer },
    };

    const { mutate: addCaseBookmark } = useMutation({
        mutationFn: async (id: string) => {
            const result = await postCaseBookmark(id);
            return result;
        },
    });

    const { mutate: reject } = useMutation({
        mutationFn: async (id: string) => {
            const result = await rejectCase(id);
            if (result?.status === requestStatus.OK) {
                navigate(pathname);
            }
            return result;
        },
    });

    const handleActionLawyerClick = () => {
        if (caseId && lawyerValue?.id && action) {
            actions[action].request({
                clientCaseId: caseId,
                lawyerId: lawyerValue?.id,
            });
        }
    };

    // const handleClickNewDoc = (event: React.MouseEvent) => {
    //   setTypeAction("new_file");
    // };

    // const handleSearch = (event: React.MouseEvent) => {
    //   setTypeAction("view_search");
    // };
    // const handleClickAddLawyer = () => {
    //     setAddLawyerModalOpened(true);
    //     setAction(Action.add);
    // };

    const handleClickTransferToLawyer = () => {
        setAddLawyerModalOpened(true);
        setAction(Action.edit);
    };

    const handleClickRejectCase = () => {
        reject(caseId);
    };

    const handleClickAddCaseBookmark = () => {
        addCaseBookmark(caseId);
    };

    const isFav = true;

    const lawyerToolbar: NavDataInterface[] = [
        {
            id: 'caseToolbar0',
            iconType: 'regular',
            icon: 'fa-chevron-left',
            label: 'Retour',
            link: '/profile/clients?id=' + clientCase?.userId,
        },
        // {
        //   id: "caseToolbar1",
        //   iconType: "regular",
        //   icon: "fa-file-lines",
        //   label: "Nouveau document",
        //   onClick: handleClickNewDoc,
        //   isDisabled: !clientCase,
        // },

        // {
        //   id: "caseToolbar3",
        //   icon: "fa-file-zipper",
        //   label: "Exporter dossier",
        //   isDisabled: !clientCase,
        // },
        {
            id: 'caseToolbar4',
            icon: 'fa-star',
            label: (isFav ? 'Ajouter aux' : 'Retirer des') + 'favoris',
            isDisabled: !clientCase,
            onClick: handleClickAddCaseBookmark,
        },
        // {
        //   id: "caseToolbar5",
        //   icon: "fa-search",
        //   label: "Articles ou Jurisprudences",
        //   onClick: handleSearch,
        //   isDisabled: !clientCase,
        // },
        // {
        //   id: "caseToolbar6",
        //   icon: "fa-file-invoice-dollar",
        //   label: "Nouvelle facture",
        //   onClick: handleSearch,
        //   isDisabled: !clientCase,
        // },
        {
            id: 'caseToolbar7',
            icon: 'fa-share-from-square',
            label: 'Transférer dossier',
            position: 'end',
            isDisabled: !clientCase,
            onClick: handleClickTransferToLawyer,
        },
        {
            id: 'caseToolbar8',
            icon: 'fa-trash',
            label: 'Refuser dossier',
            position: 'end',
            isDisabled: !clientCase,
            onClick: handleClickRejectCase,
        },
    ];

    // ajouter une page de détails des étapes du dossier

    const steps: CaseStepType[] = [
        {
            id: 'step1',
            label: 'Création du dossier',
            date: new Date('2024/04/02 12:34:56'),
            status: 'start',
        },
        {
            id: 'step2',
            label: 'Consultation',
            date: new Date('2024/04/04 15:30:00'),
            status: 'in progress',
        },
        {
            id: 'step3',
            label: 'Audience préliminaire',
            date: new Date('2024/04/05 09:40:36'),
            status: 'in progress',
        },
        {
            id: 'step4',
            label: 'Dépot de documents',
            date: new Date('2024/04/09 18:34:45'),
            status: 'end',
        },
    ];

    if (isPending) {
        return <Loader />;
    }
    if (!clientCase) {
        return <NotFound />;
    }

    return (
        <div className={styles.body}>
            <Toolbar toolbarData={lawyerToolbar} />
            <Modal
                opened={addLawyerModalOpened}
                setOpened={setAddLawyerModalOpened}
                title={'Ajouter un Avocat'}
            >
                <SearchMyLawyer onOptionSelected={setLawyerValue} />
                <Button onClick={handleActionLawyerClick}>Ajouter</Button>
            </Modal>
            <h1>Dossier {clientCase?.caseRef}</h1>
            <CaseDescription clientCase={clientCase} />
            <PersonnalData caseData={clientCase} />
            <CaseFormEditorResponses clientCase={clientCase} />
            {/* <CaseLaws /> */}
            <JudicialCalendar clientCaseId={caseId} />
            <CaseSteps caseSteps={steps} />

            {user?.role === Role.Lawyer && (
                <CaseNotes clientCase={clientCase} />
            )}
            <div className={styles.infoContainer}>
                <h2>Fichiers:</h2>
                <FileExplorer />
            </div>
        </div>
    );
}

export default ClientCaseDetails;
