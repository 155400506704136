'use client';
import React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../helpers/classNames';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    variant?:
        | 'primary'
        | 'secondary'
        | 'primaryLight'
        | 'secondaryLight'
        | 'primaryLightBordered'
        | 'secondaryLightBordered'
        | 'tertiary';
    className?: any;
    size?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (param: any) => void;
    link: string;
    icon?: string;
    children: React.ReactNode;
    disabled?: boolean;
}

function ButtonLink(props: Props) {
    const {
        className,
        variant = 'primary',
        size,
        type,
        onClick,
        link,
        icon,
        children,
        disabled,
    } = props;

    return (
        <Link
            to={link}
            className={classNames([
                styles.btn,
                className,
                styles[variant],
                size,
                type,
            ])}
            onClick={onClick}
            aria-disabled={disabled}
        >
            {icon && <FaIcon>{icon}</FaIcon>}
            {children}
        </Link>
    );
}

export default ButtonLink;
