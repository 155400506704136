import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../AlertProvider';
import Chip from '../../../../components/Chip';
import SearchBySpeciality from '../../../../components/SearchBar/SearchBySpeciality';
import { alertType } from '../../../../constants/alertTypeConstant';
import { successStatus } from '../../../../constants/requestStatusContants';
import {
    addMySpeciality,
    deleteMySpeciality,
} from '../../../../services/specialityService';
import SpecialityType from '../../../../types/SpecialityType';
import styles from './style.module.scss';

interface Props {
    specialities: SpecialityType[];
}

function SpecialitiesSettings(props: Props) {
    const { specialities } = props;
    const alert = useAlert();
    const [lawyerSpecialities, setLawyerSpecialities] = useState<
        SpecialityType[]
    >([]);

    useEffect(() => {
        setLawyerSpecialities(specialities);
    }, [specialities]);

    const { mutate: addSpeciality } = useMutation({
        mutationFn: async (id: string) => {
            if (id.length > 0) {
                const { data, status } = await addMySpeciality(id);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });
    const { mutate: deleteSpeciality } = useMutation({
        mutationFn: async (id: string) => {
            if (id.length > 0) {
                const { data, status } = await deleteMySpeciality(id);

                if (successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Vos modifications ont été enregistrées avec succès',
                        type: alertType[status],
                    });
                } else {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const onOptionSelected = (speciality: SpecialityType | null) => {
        const lawyerIdSpecialities = lawyerSpecialities.map(
            (spe: SpecialityType) => {
                return spe.id;
            }
        );
        if (speciality) {
            if (!lawyerIdSpecialities.includes(speciality.id)) {
                const newSpeciality = [...lawyerSpecialities, speciality];
                setLawyerSpecialities(newSpeciality);
            }
            addSpeciality(speciality.id);
        }
    };
    const onDeleteSpeciality = (
        event: MouseEvent,
        speciality: SpecialityType
    ) => {
        const newSpeciality = lawyerSpecialities.filter(
            (spe: SpecialityType) => {
                return spe.id !== speciality.id;
            }
        );
        setLawyerSpecialities(newSpeciality);
        deleteSpeciality(speciality.id);
    };

    return (
        <div>
            <SearchBySpeciality
                onOptionSelected={onOptionSelected}
                reset={true}
            />
            <div className={styles.listSpecialities}>
                {lawyerSpecialities.map((speciality: SpecialityType) => (
                    <Chip
                        key={speciality.id}
                        onDelete={onDeleteSpeciality}
                        data={speciality}
                    >
                        {speciality.name}
                    </Chip>
                ))}
            </div>
        </div>
    );
}

export default SpecialitiesSettings;
