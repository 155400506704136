import FormElementType from '../../../../../types/FormElementType';
import FormElement from './FormElement';
import styles from './style.module.scss';

interface Props {
    form: FormElementType[];
    setForm: (param: FormElementType[]) => void;
}

function FormContainer(props: Props) {
    const { form, setForm } = props;

    return (
        <div className={styles.container}>
            {form
                ?.sort((a, b) => {
                    return a.position - b.position;
                })
                .map((element) => (
                    <FormElement
                        key={'formEmlement' + element.name}
                        element={element}
                        form={form}
                        setForm={setForm}
                    />
                ))}
        </div>
    );
}

export default FormContainer;
