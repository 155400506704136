import { faBuildingCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './style.module.scss';

function NoLawfirmFound() {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faBuildingCircleXmark} />
                </div>
                <div className={styles.title}>Aucun Cabinet Trouvé</div>
                <div className={styles.content}>
                    Nous n'avons trouvé aucun cabinet correspondant à vos
                    critères de recherche.
                </div>
            </div>
            <div className={styles.footer}>
                <p>
                    Essayez d'ajuster vos filtres de recherche ou parcourez
                    notre répertoire pour les avocats disponibles.
                </p>
            </div>
        </div>
    );
}

export default NoLawfirmFound;
