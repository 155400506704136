import { faAdd, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../components/Button';
import ButtonLink from '../../../../components/ButtonLink';
import { classNames } from '../../../../helpers/classNames';
import styles from './style.module.scss';

interface Props {
    userId: string;
}

function BillsTable(props: Props) {
    const { userId } = props;
    // ajouter une page de détails des étapes du dossier
    const bills = [
        {
            id: '1',
            userId: userId,
            number: 'b1230',
            creationDate: new Date(),
            limiteDate: new Date(new Date().setDate(new Date().getDate() + 1)),
            paidDate: new Date(),
            status: 'Payé', // payée/retard
        },
        {
            id: '1',
            userId: userId,
            number: 'b1232',
            creationDate: new Date(),
            limiteDate: new Date(new Date().setDate(new Date().getDate() + 1)),
            paidDate: null,
            status: 'En attente', // payée/retard
        },
        {
            id: '1',
            userId: userId,
            number: 'b1234',
            creationDate: new Date(),
            limiteDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            paidDate: null,
            status: 'En attente', // payée/retard
        },
    ];

    // - mettre un tableau avec toutes les factures
    // - possibiliter des creer/visualiser des factures
    // - ajouter le nombre d'heure travailler sur la facture
    return (
        <div className={styles.container}>
            <div className={styles.btns}>
                <Button>
                    <FontAwesomeIcon icon={faAdd} /> Nouvelle facture
                </Button>
            </div>
            <div className={styles.table}>
                <table>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">n°</th>
                            <th scope="col">date de création</th>
                            <th scope="col">date limite de paiement</th>
                            <th scope="col">date de paiement</th>
                            <th scope="col">status</th>
                            <th scope="col">action</th>
                        </tr>
                    </thead>
                    <tbody className={styles.events}>
                        {bills?.map((bill) => (
                            <tr className={styles.event} key={bill.id}>
                                <td className={styles.iconCol}>
                                    <FontAwesomeIcon
                                        icon={faFileInvoiceDollar}
                                    />
                                </td>
                                <td>{bill.number}</td>
                                <td>
                                    {bill.creationDate?.toLocaleDateString()}
                                </td>
                                <td>{bill.limiteDate?.toLocaleDateString()}</td>
                                <td>{bill.paidDate?.toLocaleDateString()}</td>
                                <td>
                                    <div
                                        className={classNames([
                                            styles.status,
                                            bill.status === 'Payé'
                                                ? 'success'
                                                : new Date() > bill.limiteDate
                                                  ? 'error'
                                                  : 'inProgress',
                                        ])}
                                    >
                                        {bill.status}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.actionBtn}>
                                        <ButtonLink link={'bill/' + bill.id}>
                                            Ouvrir
                                        </ButtonLink>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BillsTable;
