import { Navigate, Outlet } from 'react-router';
import { useAuth } from '../../AuthProvider';
import { dashboardPage } from '../../constants/pagesRoutesContants';
import { storageItems } from '../../constants/storageItemsContants';
import styles from './style.module.scss';

function Authentication() {
    const { isAuth } = useAuth();
    if (isAuth) {
        const defaultUrl = dashboardPage.path;
        const previous_url = sessionStorage.getItem(storageItems.landingPage);
        if (previous_url) {
            return <Navigate to={previous_url} replace />;
        }
        return <Navigate to={defaultUrl} replace />;
    }

    return (
        <div>
            <div className={styles.container}>
                <Outlet />
            </div>
        </div>
    );
}

export default Authentication;
