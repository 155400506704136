'use client';

import {
    faEnvelope,
    faEye,
    faEyeSlash,
    faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type React from 'react';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthProvider';
import Input from '../../../components/Input';
import { registerPage } from '../../../constants/pagesRoutesContants';
import { validationFunctions } from '../../../constants/validationConstants';
import styles from './style.module.scss';

const Login: React.FC = () => {
    const { userLogin } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const setValueOnChange: {
        [key: string]: React.Dispatch<React.SetStateAction<string>>;
    } = {
        email: setEmail,
        password: setPassword,
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (email.length === 0 || password.length === 0) {
            return;
        }
        userLogin({
            email: email,
            password: password,
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (Object.keys(setValueOnChange).includes(name)) {
            setValueOnChange[name](value);
        }
    };

    const emailError = validationFunctions.email(email);
    const passwordError = validationFunctions.loginPassword(password);

    return (
        <div className={styles.authPage}>
            <div className={styles.authContainer}>
                <div className={styles.authCard}>
                    <div className={styles.authHeader}>
                        <h2 className={styles.authTitle}>Connexion</h2>
                        <p className={styles.authSubtitle}>
                            Accédez à votre compte Liber'All
                        </p>
                    </div>

                    <form className={styles.authForm} onSubmit={handleSubmit}>
                        <div className={styles.formGroup}>
                            <div className={styles.inputWithIcon}>
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    placeholder="votre@email.com"
                                    value={email}
                                    onChange={handleChange}
                                    isRequired
                                    error={emailError.errorMessage}
                                    icon={faEnvelope}
                                />
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <div className={styles.labelWithLink}>
                                <Link
                                    to="/mot-de-passe-oublie"
                                    className={styles.forgotPassword}
                                >
                                    Mot de passe oublié ?
                                </Link>
                            </div>
                            <div className={styles.inputWithIcon}>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    label="Mot de passe"
                                    placeholder="••••••••"
                                    value={password}
                                    onChange={handleChange}
                                    isRequired
                                    error={passwordError.errorMessage}
                                    icon={faLock}
                                />
                                <button
                                    type="button"
                                    className={styles.passwordToggle}
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    aria-label={
                                        showPassword
                                            ? 'Masquer le mot de passe'
                                            : 'Afficher le mot de passe'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                    />
                                </button>
                            </div>
                        </div>

                        <div className={styles.formCheckbox}>
                            <input
                                type="checkbox"
                                id="remember-me"
                                checked={rememberMe}
                                onChange={(e) =>
                                    setRememberMe(e.target.checked)
                                }
                            />
                            <label htmlFor="remember-me">
                                Se souvenir de moi
                            </label>
                        </div>

                        <button
                            type="submit"
                            className={`${styles.btn} ${styles.btnPrimary} ${styles.btnLarge}`}
                        >
                            Se connecter
                        </button>
                    </form>
                    <div className={styles.authFooter}>
                        <p>
                            Vous n'avez pas de compte ?{' '}
                            <Link to={registerPage.path}>Créer un compte</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
