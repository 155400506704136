import { useOutletContext } from 'react-router-dom';
import ButtonLink from '../../../../components/ButtonLink';
import {
    clientsPage,
    myCasesPage,
} from '../../../../constants/pagesRoutesContants';
import Role from '../../../../enums/RoleEnum';
import { GetTimeGapString } from '../../../../helpers/date';
import MeetingType from '../../../../types/MeetingDateType';
import UserType from '../../../../types/UserType';
import styles from './style.module.scss';

interface Props {
    events: MeetingType[];
}

function MeetingsTable(props: Props) {
    const { events } = props;
    const user: UserType = useOutletContext();

    return (
        <div className={styles.meetings}>
            <table>
                <thead>
                    <tr>
                        <th scope="col">
                            {user?.role === Role.Lawyer ? 'Client' : 'Avocat'}
                        </th>
                        <th scope="col">Type rendez-vous</th>
                        <th scope="col">Date</th>
                        <th scope="col">Durée</th>
                        <th scope="col">Sujet</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody className={styles.events}>
                    {events?.map((event) => (
                        <tr className={styles.event} key={event.id}>
                            <td>
                                {user?.role === Role.Lawyer
                                    ? event.client
                                    : event.lawyer}
                            </td>
                            <td>{event.title}</td>
                            <td>{new Date(event.start).toLocaleString()}</td>
                            <td>{GetTimeGapString(event.start, event.end)}</td>
                            <td>{event.subject}</td>
                            <td>
                                {event.clientCaseId && (
                                    <ButtonLink
                                        link={
                                            (user?.role === Role.Lawyer
                                                ? clientsPage.path
                                                : myCasesPage.path) +
                                            '?id=' +
                                            event.clientCaseId
                                        }
                                    >
                                        Voir le dossier
                                    </ButtonLink>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MeetingsTable;
