import {
    faChartBar,
    faCircleCheck,
    faClock,
    faEnvelope,
    faFileLines,
    faMagnifyingGlass,
    faShield,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Divider from '../../components/Divider';
import SearchByAddress from '../../components/SearchBar/SearchByAddress';
import SearchBySpeciality from '../../components/SearchBar/SearchBySpeciality';
import { lawyersPage, registerPage } from '../../constants/pagesRoutesContants';
import AddressType from '../../types/AddressType';
import SpecialityType from '../../types/SpecialityType';
import styles from './style.module.scss';

function Home() {
    const [activeTab, setActiveTab] = useState<'specialty' | 'location'>(
        'specialty'
    );

    const [specialityValue, setSpecialityValue] =
        useState<SpecialityType | null>();
    const [addressValue, setAddressyValue] = useState<AddressType | null>();

    const handleTabChange = (tab: 'specialty' | 'location') => {
        setActiveTab(tab);
    };

    return (
        <div className={styles.container}>
            <section className={styles.hero}>
                <div className={styles.heroOverlay}></div>
                <div className={styles.heroContent}>
                    <div className={styles.heroText}>
                        <h2>Trouvez des Avocats dédiés à vos cas</h2>
                        <p>
                            Une plateforme qui simplifie la relation entre
                            avocats et clients
                        </p>
                    </div>
                    <div className={styles.searchContainer}>
                        <div className={styles.searchTabs}>
                            <button
                                className={`${styles.tabBtn} ${activeTab === 'specialty' ? styles.active : ''}`}
                                onClick={() => handleTabChange('specialty')}
                            >
                                Recherche par spécialité
                            </button>
                            <button
                                className={`${styles.tabBtn} ${activeTab === 'location' ? styles.active : ''}`}
                                onClick={() => handleTabChange('location')}
                            >
                                Recherche par localisation
                            </button>
                        </div>

                        {activeTab === 'specialty' && (
                            <div className={styles.tabContent}>
                                <div className={styles.searchForm}>
                                    <SearchBySpeciality
                                        onOptionSelected={setSpecialityValue}
                                        placeholder="Sélectionnez la spécialité de votre avocat"
                                        label="Domaine juridique"
                                        speciality={specialityValue?.id}
                                    />
                                    <div>
                                        <Link
                                            to={
                                                lawyersPage.path +
                                                ((specialityValue &&
                                                    '?speciality=' +
                                                        specialityValue.id) ||
                                                    '')
                                            }
                                            reloadDocument
                                            className={`${styles.btn} ${styles.btnPrimary}`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass}
                                                className={styles.icon}
                                            />
                                            Rechercher
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === 'location' && (
                            <div className={styles.tabContent}>
                                <div className={styles.searchForm}>
                                    <SearchByAddress
                                        userAddress={
                                            addressValue?.address || ''
                                        }
                                        onOptionSelected={setAddressyValue}
                                        placeholder="Ex: Paris, Lyon, Marseille"
                                        label="Ville"
                                    />
                                    <SearchBySpeciality
                                        onOptionSelected={setSpecialityValue}
                                        placeholder="Sélectionnez la spécialité de votre avocat"
                                        label="Spécialité (optionnel)"
                                        speciality={specialityValue?.id}
                                    />
                                    <Link
                                        to={
                                            lawyersPage.path +
                                            ((addressValue &&
                                                '?address=' +
                                                    addressValue.address) ||
                                                '') +
                                            ((specialityValue &&
                                                (addressValue?.address &&
                                                addressValue?.address?.length >
                                                    0
                                                    ? '&'
                                                    : '?') +
                                                    'speciality=' +
                                                    specialityValue.id) ||
                                                '')
                                        }
                                        reloadDocument
                                        className={`${styles.btn} ${styles.btnPrimary}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                            className={styles.icon}
                                        />
                                        Rechercher
                                    </Link>
                                </div>
                            </div>
                        )}

                        <div className={styles.searchInfo}>
                            Plus de 2000 avocats disponibles sur notre
                            plateforme
                        </div>
                    </div>
                </div>
            </section>

            {/* For Clients Section */}
            <section id="clients" className={styles.section}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>Pour les clients</h2>

                    <div className={styles.twoColumns}>
                        <div className={styles.column}>
                            <img
                                src={'/img/office-620817_1280.jpg'}
                                alt="Services pour les clients"
                                className={styles.featureImage}
                            />
                        </div>
                        <div className={styles.column}>
                            <h3 className={`${styles.subtitle} ${styles.blue}`}>
                                Simplifiez vos démarches juridiques
                            </h3>
                            <p className={styles.text}>
                                Liber'All vous permet de trouver facilement
                                l'avocat qui correspond à vos besoins et de
                                suivre l'avancement de votre dossier en temps
                                réel.
                            </p>

                            <h3 className={`${styles.subtitle} ${styles.blue}`}>
                                Avantages pour les clients
                            </h3>
                            <Divider variant="primary" width="50%" />
                            <ul className={styles.benefitsList}>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Recherche d'avocats par spécialité ou
                                        localisation
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Consultation des avis et évaluations des
                                        autres clients
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Suivi en temps réel de l'avancement de
                                        votre dossier
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Communication sécurisée avec votre
                                        avocat
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Accès à vos documents juridiques à tout
                                        moment
                                    </span>
                                </li>
                            </ul>

                            <div className={styles.ctaContainer}>
                                <Link
                                    to={registerPage.path}
                                    reloadDocument
                                    className={`${styles.btn} ${styles.btnPrimary} ${styles.btnLarge}`}
                                >
                                    Créer un compte client
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* For Lawyers Section */}
            <section
                id="lawyers"
                className={`${styles.section} ${styles.altBg}`}
            >
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>Pour les avocats</h2>

                    <div className={`${styles.twoColumns} ${styles.reverse}`}>
                        <div className={styles.column}>
                            <h3 className={`${styles.subtitle} ${styles.blue}`}>
                                Développez votre cabinet
                            </h3>
                            <p className={styles.text}>
                                Liber'All vous offre une visibilité accrue et
                                des outils de gestion performants pour optimiser
                                votre activité et vous concentrer sur
                                l'essentiel : Representer et conseiller vos
                                clients.
                            </p>

                            <h3 className={`${styles.subtitle} ${styles.blue}`}>
                                Avantages pour les avocats
                            </h3>
                            <ul className={styles.benefitsList}>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Acquisition de nouveaux clients
                                        qualifiés
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Gestion simplifiée de vos dossiers et de
                                        votre agenda
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Facturation et suivi des paiements
                                        intégrés
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Communication sécurisée avec vos clients
                                    </span>
                                </li>
                                <li className={styles.benefitItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Statistiques et analyses de votre
                                        activité
                                    </span>
                                </li>
                            </ul>

                            <div className={styles.ctaContainer}>
                                <Link
                                    to={registerPage.path}
                                    reloadDocument
                                    className={`${styles.btn} ${styles.btnPrimary} ${styles.btnLarge}`}
                                >
                                    Rejoindre notre réseau d'avocats
                                </Link>
                            </div>
                        </div>
                        <div className={styles.column}>
                            <img
                                src={'/img/laptop-3196481_1280.jpg'}
                                alt="Services pour les avocats"
                                className={styles.featureImage}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* Features Section */}
            <section id="features" className={styles.section}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>Nos fonctionnalités</h2>

                    <div className={styles.featuresGrid}>
                        {/* Feature 1 */}
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIconContainer} ${styles.blue}`}
                            >
                                <FontAwesomeIcon
                                    icon={faFileLines}
                                    className={styles.featureIcon}
                                />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Gestion des dossiers
                            </h3>
                            <p className={styles.featureText}>
                                Centralisez tous vos documents, suivez les
                                échéances et l'avancement de chaque dossier en
                                temps réel.
                            </p>
                        </div>

                        {/* Feature 2 */}
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIconContainer} ${styles.orange}`}
                            >
                                <FontAwesomeIcon
                                    icon={faClock}
                                    className={styles.featureIcon}
                                />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Suivi en temps réel
                            </h3>
                            <p className={styles.featureText}>
                                Les clients peuvent suivre l'avancement de leur
                                dossier à tout moment et recevoir des
                                notifications à chaque étape importante.
                            </p>
                        </div>

                        {/* Feature 3 */}
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIconContainer} ${styles.blue}`}
                            >
                                <FontAwesomeIcon
                                    icon={faShield}
                                    className={styles.featureIcon}
                                />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Sécurité des données
                            </h3>
                            <p className={styles.featureText}>
                                Toutes vos données et communications sont
                                protégées par un chiffrement de bout en bout
                                conforme aux normes RGPD.
                            </p>
                        </div>

                        {/* Feature 4 */}
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIconContainer} ${styles.orange}`}
                            >
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    className={styles.featureIcon}
                                />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Mise en relation
                            </h3>
                            <p className={styles.featureText}>
                                Notre algorithme intelligent met en relation les
                                clients avec les avocats les plus adaptés à
                                leurs besoins spécifiques.
                            </p>
                        </div>

                        {/* Feature 5 */}
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIconContainer} ${styles.blue}`}
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className={styles.featureIcon}
                                />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Messagerie sécurisée
                            </h3>
                            <p className={styles.featureText}>
                                Communiquez directement avec votre avocat ou vos
                                clients via notre messagerie intégrée et
                                sécurisée.
                            </p>
                        </div>

                        {/* Feature 6 */}
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIconContainer} ${styles.orange}`}
                            >
                                <FontAwesomeIcon
                                    icon={faChartBar}
                                    className={styles.featureIcon}
                                />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Statistiques et rapports
                            </h3>
                            <p className={styles.featureText}>
                                Les avocats peuvent suivre leur activité grâce à
                                des tableaux de bord et des rapports détaillés.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className={`${styles.section} ${styles.altBg}`}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>Témoignages</h2>

                    <div className={styles.testimonialsGrid}>
                        {/* Testimonial 1 - Client */}
                        <div className={styles.testimonialCard}>
                            <div className={styles.testimonialHeader}>
                                <div className={styles.testimonialAvatar}></div>
                                <div className={styles.testimonialAuthor}>
                                    <h4>Sophie M.</h4>
                                    <p>Cliente</p>
                                </div>
                            </div>
                            <p className={styles.testimonialText}>
                                "Grâce à Liber'All, j'ai trouvé un avocat
                                spécialisé en droit de la famille en quelques
                                clics. Le suivi de mon dossier de divorce a été
                                simplifié et j'ai pu rester informée à chaque
                                étape."
                            </p>
                        </div>

                        {/* Testimonial 2 - Lawyer */}
                        <div className={styles.testimonialCard}>
                            <div className={styles.testimonialHeader}>
                                <div className={styles.testimonialAvatar}></div>
                                <div className={styles.testimonialAuthor}>
                                    <h4>Maître Thomas L.</h4>
                                    <p>Avocat en droit des affaires</p>
                                </div>
                            </div>
                            <p className={styles.testimonialText}>
                                "Depuis que j'ai rejoint la plateforme, mon
                                cabinet a gagné en visibilité et j'ai pu
                                développer ma clientèle. Les outils de gestion
                                me font gagner un temps précieux au quotidien."
                            </p>
                        </div>

                        {/* Testimonial 3 - Client */}
                        <div className={styles.testimonialCard}>
                            <div className={styles.testimonialHeader}>
                                <div className={styles.testimonialAvatar}></div>
                                <div className={styles.testimonialAuthor}>
                                    <h4>Marc D.</h4>
                                    <p>Client</p>
                                </div>
                            </div>
                            <p className={styles.testimonialText}>
                                "En tant qu'entrepreneur, j'avais besoin d'un
                                accompagnement juridique pour ma startup.
                                Liber'All m'a permis de trouver rapidement un
                                avocat spécialisé qui a parfaitement compris mes
                                enjeux."
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className={styles.ctaSection}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.ctaTitle}>
                        Prêt à simplifier vos démarches juridiques ?
                    </h2>
                    <p className={styles.ctaText}>
                        Rejoignez notre plateforme et découvrez une nouvelle
                        façon de gérer vos affaires juridiques.
                    </p>
                    <div className={styles.ctaButtons}>
                        <Link
                            to={registerPage.path + '?type=client'}
                            reloadDocument
                            className={`${styles.btn} ${styles.btnWhite} ${styles.btnLarge}`}
                        >
                            Je suis un client
                        </Link>
                        <Link
                            to={registerPage.path + '?type=avocat'}
                            reloadDocument
                            className={`${styles.btn} ${styles.btnOutlineWhite} ${styles.btnLarge}`}
                        >
                            Je suis un avocat
                        </Link>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className={styles.section}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>Contactez-nous</h2>

                    <div className={styles.twoColumns}>
                        <div className={styles.column}>
                            <h3 className={`${styles.subtitle} ${styles.blue}`}>
                                Nos coordonnées
                            </h3>

                            <div className={styles.contactInfo}>
                                {/* <div className={styles.contactItem}>
                                    <FontAwesomeIcon
                                        icon={faLocationDot}
                                        className={styles.contactIcon}
                                    />
                                    <div>
                                        <h4>Adresse</h4>
                                        <p>
                                            123 Avenue de la Justice, 75000
                                            Paris
                                        </p>
                                    </div>
                                </div>

                                <div className={styles.contactItem}>
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        className={styles.contactIcon}
                                    />
                                    <div>
                                        <h4>Téléphone</h4>
                                        <p>01 23 45 67 89</p>
                                    </div>
                                </div> */}

                                <div className={styles.contactItem}>
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className={styles.contactIcon}
                                    />
                                    <div>
                                        <h4>Email</h4>
                                        <p>contact@liber-all.fr</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className={styles.socialLinks}>
                <h4>Suivez-nous</h4>
                <div className={styles.socialIcons}>
                  <a href="#" className={styles.socialIcon}>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href="#" className={styles.socialIcon}>
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div> */}
                        </div>

                        <div className={styles.column}>
                            <h3 className={`${styles.subtitle} ${styles.blue}`}>
                                Envoyez-nous un message
                            </h3>

                            <form className={styles.contactForm}>
                                <div className={styles.formRow}>
                                    <div className={styles.formGroup}>
                                        <label htmlFor="name">Nom</label>
                                        <input
                                            type="text"
                                            id="name"
                                            className={styles.formControl}
                                        />
                                    </div>
                                    <div className={styles.formGroup}>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className={styles.formControl}
                                        />
                                    </div>
                                </div>

                                <div className={styles.formGroup}>
                                    <label htmlFor="subject">Sujet</label>
                                    <input
                                        type="text"
                                        id="subject"
                                        className={styles.formControl}
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        rows={5}
                                        className={styles.formControl}
                                    ></textarea>
                                </div>

                                <button
                                    type="submit"
                                    className={`${styles.btn} ${styles.btnPrimary}`}
                                >
                                    Envoyer
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
