import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideNav from '../../../components/SideNav';
import Toolbar from '../../../components/Toolbar';
import NavDataInterface from '../../../interfaces/NavDataInterface';
import OptionInterface from '../../../interfaces/OptionInterface';
import { fetchUserConversations } from '../../../services/messageService';
import ConversationType from '../../../types/ConversationType';
import ContactForm from './contactForm';
import Conversation from './conversation';
import NewConversation from './newConversation';
import styles from './style.module.scss';

function Message() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const conversationId = searchParams.get('id') || '';
    const [messageText, setMessageText] = useState<string>('');
    const { data: conversations } = useQuery({
        queryKey: ['conversations', { search: messageText }],
        queryFn: async () => {
            try {
                const { data } = await fetchUserConversations(messageText);
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const [typeAction, setTypeAction] = useState<
        'newConversationForm' | 'newConversation' | 'conversation' | null
    >(null);

    const [recipient, setRecipient] = useState<OptionInterface | null>(null);
    const handleOnConversationClick = (contact: OptionInterface) => {
        if (contact) {
            setRecipient(contact);
            setTypeAction('conversation');
        }
    };

    useEffect(() => {
        if (conversationId) {
            setTypeAction('conversation');
        }
    }, [conversationId]);

    const navData: NavDataInterface[] = conversations?.map(
        (conversation: ConversationType): NavDataInterface => {
            return {
                id: conversation.id,
                label: conversation.name,
                link: '?id=' + conversation.id,
                variant: 'tertiary',
                icon: 'fa-comments',
                iconColor: '#22b321',
            };
        }
    );

    const handleClickNewConversation = () => {
        setTypeAction('newConversationForm');
    };

    const defaultToolbar: NavDataInterface[] = [
        {
            id: 'toolbarBtn0',
            icon: 'fa-add',
            label: 'Nouveau message',
            onClick: handleClickNewConversation,
        },
    ];
    const [toolbar, setToolbar] = useState<NavDataInterface[]>(defaultToolbar);

    const viewObject = {
        conversation: {
            view: <Conversation setToolbar={setToolbar} />,
        },
        newConversationForm: {
            view: (
                <ContactForm
                    setRecipient={handleOnConversationClick}
                    setToolbar={setToolbar}
                    setTypeAction={setTypeAction}
                />
            ),
        },
        newConversation: {
            view: (
                <NewConversation
                    setToolbar={setToolbar}
                    recipient={recipient}
                />
            ),
        },
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMessageText(value);
    };

    return (
        <div className={styles.container}>
            <SideNav
                selected={conversationId}
                navData={navData}
                extendable={conversationId.length > 0}
                hasSearchBar={true}
                searchBarLabel="Destinataire :"
                handleSearchChange={handleSearchChange}
                reduceIcon={faComments}
            />
            <div className={styles.body}>
                <Toolbar
                    toolbarData={
                        conversationId.length > 0 ? toolbar : defaultToolbar
                    }
                />
                {typeAction && viewObject[typeAction].view}
            </div>
        </div>
    );
}

export default Message;
