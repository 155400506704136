import {
    bookmarkClientsEndpoint,
    bookmarkLawyersEndpoint,
} from '../constants/endpointsContants';
import request from './genericRequestService';

async function fetchContactLawyersBookmark() {
    return await request.get({
        endpoint: bookmarkLawyersEndpoint,
    });
}
async function postContactLawyerBookmark(id: string) {
    return await request.post({
        endpoint: bookmarkLawyersEndpoint,
        id,
    });
}
async function fetchContactClientsBookmark() {
    return await request.get({
        endpoint: bookmarkClientsEndpoint,
    });
}
async function postContactClientBookmark(id: string) {
    return await request.post({
        endpoint: bookmarkClientsEndpoint,
        id,
    });
}

export {
    fetchContactClientsBookmark,
    fetchContactLawyersBookmark,
    postContactClientBookmark,
    postContactLawyerBookmark,
};
