import React, { useState } from 'react';
import Button from '../../../../../../components/Button';
import FaIcon from '../../../../../../components/Icons/FaIcon';
import Input from '../../../../../../components/Input';
import OptionInterface from '../../../../../../interfaces/OptionInterface';
import styles from './style.module.scss';

interface Props {
    options: OptionInterface[];
    setOptions: (param: OptionInterface[]) => void;
}

function OptionForm(props: Props) {
    const { options, setOptions } = props;
    const [option, setOption] = useState<OptionInterface>({
        name: '',
        label: '',
    });

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setOption({
            ...option,
            name: value.toLowerCase().replaceAll(' ', '_'),
            label: value,
        });
    };

    const handleAddValue = () => {
        if (option.name && option.name.length > 0) {
            console.log('option', option);

            setOptions([...options, option]);
        }
        setOption({ name: '', label: '' });
    };

    const handleRemoveValue = (value: OptionInterface) => {
        const indexValue = options.indexOf(value);
        const newOptions = [...options];
        newOptions.splice(indexValue, 1);
        setOptions(newOptions);
    };

    return (
        <div className={styles.container}>
            <div className={styles.addValueContainer}>
                <Input
                    onChange={handleChangeValue}
                    label="Valeur"
                    value={option.value}
                />
                <Button onClick={handleAddValue}>
                    <FaIcon>fa-solid fa-plus</FaIcon>
                </Button>
            </div>
            <div className={styles.valuesContainer}>
                {options.map((option: OptionInterface) => (
                    <div key={option.name} className={styles.valueContainer}>
                        <Button
                            onClick={() => {
                                handleRemoveValue(option);
                            }}
                        >
                            <FaIcon>fa-solid fa-xmark</FaIcon>
                        </Button>
                        <span>{option.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OptionForm;
