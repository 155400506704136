'use client';

import {
    faBuilding,
    faEnvelope,
    faEye,
    faEyeSlash,
    faIdCard,
    faLock,
    faPhone,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import type React from 'react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAlert } from '../../../AlertProvider';
import PhoneForm from '../../../components/DynamicFormEditor/PhoneForm';
import Input from '../../../components/Input';
import { alertType } from '../../../constants/alertTypeConstant';
import { loginPage } from '../../../constants/pagesRoutesContants';
import { successStatus } from '../../../constants/requestStatusContants';
import { validationFunctions } from '../../../constants/validationConstants';
import RegisterInterface from '../../../interfaces/RegisterInterface';
import { register } from '../../../services/accountService';
import styles from './style.module.scss';

type UserType = 'client' | 'avocat';

const Register: React.FC = () => {
    const location = useLocation();
    const alert = useAlert();
    const [userType, setUserType] = useState<UserType>('client');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [bar, setBar] = useState('');
    const [numberLawyer, setNumberLawyer] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const setValueOnChange: {
        [key: string]: React.Dispatch<React.SetStateAction<string>>;
    } = {
        email: setEmail,
        password: setPassword,
        confirmPassword: setConfirmPassword,
        lastName: setLastName,
        firstName: setFirstName,
        phone: setPhone,
        bar: setBar,
        numberLawyer: setNumberLawyer,
    };

    // Set user type from URL parameter if available
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const type = searchParams.get('type');
        if (type === 'avocat') {
            setUserType('avocat');
        } else if (type === 'client') {
            setUserType('client');
        }
    }, [location.search]);

    const { mutate } = useMutation({
        mutationFn: async (data: RegisterInterface) => {
            const { data: result, status } = await register(data);
            if (successStatus.includes(status)) {
                alert.show({
                    children:
                        'Votre compte a été créé avec succès. Vous pouvez maintenant vous connecter.',
                    type: alertType[status],
                });
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
            return result;
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (
            !firstNameError.isValid ||
            !lastNameError.isValid ||
            !emailError.isValid ||
            !passwordError.isValid ||
            !confirmPasswordError.isValid
        ) {
            return;
        }
        mutate({
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            firstName: firstName,
            lastName: lastName,
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (Object.keys(setValueOnChange).includes(name)) {
            setValueOnChange[name](value);
        }
    };

    const handleAcceptTerms = (event: ChangeEvent<HTMLInputElement>) =>
        setAcceptTerms(event.target.checked);

    const firstNameError = validationFunctions.firstName(firstName);
    const lastNameError = validationFunctions.lastName(lastName);
    const emailError = validationFunctions.email(email);
    const passwordError = validationFunctions.newPassword(password);
    const confirmPasswordError = validationFunctions.confirmPassword({
        password,
        confirmPassword,
    });

    return (
        <div className={styles.authPage}>
            <div className={styles.authContainer}>
                <div className={styles.authCard}>
                    <div className={styles.authHeader}>
                        <h2 className={styles.authTitle}>Inscription</h2>
                        <p className={styles.authSubtitle}>
                            Créez votre compte Liber'All
                        </p>
                    </div>

                    <div className={styles.authTypeSelector}>
                        <button
                            className={`${styles.authTypeBtn} ${userType === 'client' ? styles.active : ''}`}
                            onClick={() => setUserType('client')}
                        >
                            Je suis un client
                        </button>
                        <button
                            className={`${styles.authTypeBtn} ${userType === 'avocat' ? styles.active : ''}`}
                            onClick={() => setUserType('avocat')}
                        >
                            Je suis un avocat
                        </button>
                    </div>

                    <form className={styles.authForm} onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            id="lastName"
                            name="lastName"
                            label="Nom"
                            placeholder="Votre nom"
                            value={email}
                            onChange={handleChange}
                            isRequired
                            error={lastNameError.errorMessage}
                            icon={faUser}
                        />
                        <Input
                            type="text"
                            id="firstName"
                            name="firstName"
                            label="Prénom"
                            placeholder="Votre prénom"
                            value={email}
                            onChange={handleChange}
                            isRequired
                            error={firstNameError.errorMessage}
                            icon={faUser}
                        />

                        <Input
                            type="email"
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="votre@email.com"
                            value={email}
                            onChange={handleChange}
                            isRequired
                            error={emailError.errorMessage}
                            icon={faEnvelope}
                        />
                        <PhoneForm
                            onChange={handleChange}
                            icon={faPhone}
                            value={phone}
                        />
                        {userType === 'avocat' && (
                            <>
                                <Input
                                    label="Barreau"
                                    type="text"
                                    id="bar"
                                    name="bar"
                                    placeholder="Ex: Paris, Lyon, Marseille"
                                    value={bar}
                                    onChange={(e) => setBar(e.target.value)}
                                    icon={faBuilding}
                                />
                                <Input
                                    label="Numéro d'avocat"
                                    type="text"
                                    id="numberLawyer"
                                    name="numberLawyer"
                                    placeholder="Votre numéro d'avocat"
                                    value={numberLawyer}
                                    onChange={(e) =>
                                        setNumberLawyer(e.target.value)
                                    }
                                    icon={faIdCard}
                                />
                            </>
                        )}

                        <div className={styles.inputWithIcon}>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                label="Confirmer le mot de passe"
                                placeholder="••••••••"
                                value={password}
                                onChange={handleChange}
                                isRequired
                                error={passwordError.errorMessage}
                                icon={faLock}
                            />
                            <button
                                type="button"
                                className={styles.passwordToggle}
                                onClick={() => setShowPassword(!showPassword)}
                                aria-label={
                                    showPassword
                                        ? 'Masquer le mot de passe'
                                        : 'Afficher le mot de passe'
                                }
                            >
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                />
                            </button>
                        </div>
                        <div className={styles.inputWithIcon}>
                            <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Mot de passe"
                                placeholder="••••••••"
                                value={confirmPassword}
                                onChange={handleChange}
                                isRequired
                                error={confirmPasswordError.errorMessage}
                                icon={faLock}
                            />
                            <button
                                type="button"
                                className={styles.passwordToggle}
                                onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                }
                                aria-label={
                                    showConfirmPassword
                                        ? 'Masquer le mot de passe'
                                        : 'Afficher le mot de passe'
                                }
                            >
                                <FontAwesomeIcon
                                    icon={
                                        showConfirmPassword ? faEyeSlash : faEye
                                    }
                                />
                            </button>
                        </div>
                        <div className={styles.formCheckbox}>
                            <input
                                type="checkbox"
                                id="acceptTerms"
                                name="acceptTerms"
                                checked={acceptTerms}
                                onChange={handleAcceptTerms}
                                required
                            />
                            <label htmlFor="acceptTerms">
                                J'accepte les{' '}
                                <Link to="#">conditions générales</Link> et la{' '}
                                <Link to="#">politique de confidentialité</Link>
                            </label>
                        </div>

                        <button
                            type="submit"
                            className={`${styles.btn} ${styles.btnPrimary} ${styles.btnLarge}`}
                        >
                            Créer mon compte
                        </button>
                    </form>
                    <div className={styles.authFooter}>
                        <p>
                            Vous avez déjà un compte ?{' '}
                            <Link to={loginPage.path}>Se connecter</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
