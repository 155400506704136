import Checkbox from '../components/Checkbox';
import AddressForm from '../components/DynamicFormEditor/AddressForm';
import DateForm from '../components/DynamicFormEditor/DateForm';
import EmailForm from '../components/DynamicFormEditor/EmailForm';
import IdentityForm from '../components/DynamicFormEditor/IdentityForm';
import PhoneForm from '../components/DynamicFormEditor/PhoneForm';
import FileInput from '../components/FileInput';
import Input from '../components/Input';
import Radio from '../components/Radio';
import Select from '../components/Select';
import Textarea from '../components/Textarea';

interface FormElement {
    name: string;
    label: string;
    element: any;
    description: string;
    icon: string;
    fields: string[];
}

export const formElements: { [key: string]: FormElement } = {
    identity: {
        name: 'identity',
        label: 'Identité',
        element: IdentityForm,
        description:
            "Formulaire permettant de renseigner les informations personnelles d'identité, comme le nom et le prénom.",
        icon: 'fa-user',
        fields: ['civility', 'lastName', 'firstName'],
    },
    address: {
        name: 'address',
        label: 'Adresse',
        element: AddressForm,
        description: 'Champ permettant de saisir une adresse postale complète.',
        icon: 'fa-map-marker-alt',
        fields: ['streetName', 'zipCode', 'city', 'country'],
    },
    phone: {
        name: 'phone',
        label: 'Téléphone',
        element: PhoneForm,
        description:
            'Champ permettant de saisir un numéro de téléphone valide.',
        icon: 'fa-phone',
        fields: ['phone'],
    },
    email: {
        name: 'email',
        label: 'Email',
        element: EmailForm,
        description:
            "Champ dédié à la saisie d'une adresse email au format valide.",
        icon: 'fa-envelope',
        fields: ['email', 'confirmEmail'],
    },
    date: {
        name: 'date',
        label: 'Date',
        element: DateForm,
        description:
            "Sélecteur permettant de choisir une date à partir d'un calendrier.",
        icon: 'fa-calendar-alt',
        fields: ['date'],
    },
    file: {
        name: 'file',
        label: 'Fichier',
        element: FileInput,
        description:
            "Champ permettant de télécharger un fichier depuis l'ordinateur de l'utilisateur.",
        icon: 'fa-file-upload',
        fields: ['file'],
    },
    input: {
        name: 'input',
        label: 'Texte',
        element: Input,
        description: 'Champ de saisie simple pour entrer un texte court.',
        icon: 'fa-pencil-alt',
        fields: ['input'],
    },
    textarea: {
        name: 'textarea',
        label: 'Texte long',
        element: Textarea,
        description:
            "Zone de texte permettant d'écrire un texte long, comme un commentaire ou une description.",
        icon: 'fa-align-left',
        fields: ['textarea'],
    },
    select: {
        name: 'select',
        label: 'Sélection',
        element: Select,
        description:
            'Liste déroulante permettant de choisir une option parmi plusieurs.',
        icon: 'fa-list',
        fields: ['select'],
    },
    radio: {
        name: 'radio',
        label: 'Choix unique',
        element: Radio,
        description:
            'Groupe de boutons radio permettant de sélectionner une seule option.',
        icon: 'fa-dot-circle',
        fields: ['radio'],
    },
    checkbox: {
        name: 'checkbox',
        label: 'Choix multiple',
        element: Checkbox,
        description:
            'Cases à cocher permettant de sélectionner une ou plusieurs options.',
        icon: 'fa-check-square',
        fields: ['checkbox'],
    },
};
