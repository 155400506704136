export const homePage = {
    path: '/',
    name: 'Accueil',
};
export const loginPage = {
    path: '/login',
    name: 'Connexion',
};
export const registerPage = {
    path: '/register',
    name: 'Inscription',
};
export const profilePage = {
    path: '/profile',
    name: 'Profil',
};
export const dashboardPage = {
    path: '/profile/dashboard',
    name: 'Dashboard',
};
export const clientsPage = {
    path: '/profile/clients',
    name: 'Clients',
};
export const clientsIdPage = {
    path: '/profile/clients?id=',
    name: 'Clients',
};

export const clientsCaseIdPage = {
    path: '/profile/clients/case/',
    name: 'Dossier Client',
};

export const clientsBillIdPage = {
    path: '/profile/clients/bill/',
    name: 'Facture Client',
};

export const myCasesPage = {
    path: '/profile/mycases',
    name: 'Mes Dossiers',
};
export const myCasesIdPage = {
    path: '/profile/mycases?id=',
    name: 'Mes Dossiers',
};
export const meetingsPage = {
    path: '/profile/meetings',
    name: 'Rendez-vous',
};
export const settingsPage = {
    path: '/profile/settings',
    name: 'Paramètres',
};
export const formsPage = {
    path: '/profile/forms',
    name: 'Formulaires',
};
export const formsCreatePage = {
    path: '/profile/forms/create',
    name: 'Formulaires',
};
export const formsEditPage = {
    path: '/profile/forms/edit/',
    name: 'Formulaires',
};
export const informationFormsPage = {
    path: '/information_forms',
    name: 'Formulaires',
};
export const informationFormsIdPage = {
    path: '/information_forms/',
    name: 'Formulaires',
};
export const messagesPage = {
    path: '/profile/messages',
    name: 'Messages',
};
export const messagesIdPage = {
    path: '/profile/messages#',
    name: 'Messages',
};
export const contactPage = {
    path: '/profile/contact',
    name: 'Contacts',
};
export const contactIdPage = {
    path: '/profile/contact?id=',
    name: 'Contacts',
};
export const notePage = {
    path: '/profile/note',
    name: 'Notes',
};
export const noteIdPage = {
    path: '/profile/note#',
    name: 'Notes',
};
export const availabilitiesPage = {
    path: '/profile/availabilities',
    name: 'Horaires',
};
export const servicesPage = {
    path: '/profile/services',
    name: 'Services',
};
export const statisticsPage = {
    path: '/profile/statistics',
    name: 'Statistiques',
};
export const billingPage = {
    path: '/profile/billing',
    name: 'Facturation',
};
export const lawfirmSettingsPage = {
    path: '/profile/lawfirmSettings',
    name: 'Cabinet',
};
export const resetPage = {
    path: '/resetPassword',
    name: 'Mot de passe oublié',
};
export const forgotPage = {
    path: '/forgotPassword',
    name: 'Mot de passe oublié',
};
export const enrollLawyerPage = {
    path: '/lawyers/enroll',
    name: "S'enregistrer",
};
export const lawyersPage = {
    path: '/lawyers',
    name: 'Avocats',
};
export const lawyersIdPage = {
    path: '/lawyers/',
    name: 'Avocats',
};
export const lawfirmsPage = {
    path: '/lawfirms',
    name: 'Cabinets',
};
export const lawfirmsIdPage = {
    path: '/lawfirms/',
    name: 'Cabinets',
};
export const pricingPage = {
    path: '/pricing',
    name: 'Offres de service',
};
export const offersPage = {
    path: '/offers',
    name: 'Offres de service',
};
export const cguvPage = {
    path: '/cguv',
    name: "Conditions générales d'utilisation et de vente",
};
export const featuresPage = {
    path: '/features',
    name: 'Fonctionnalités',
};
export const liberallContactPage = {
    path: '/Contact',
    name: 'Contact',
};
