import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    name?: string;
    value?: string;
    label?: string;
    icon?: IconProp;
}

function PhoneForm(props: Props) {
    const {
        onChange,
        value = '',
        name = 'phone',
        label = 'Téléphone',
        icon,
    } = props;
    return (
        <div className={styles.divInput}>
            <Input
                label={label}
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                max={10}
                icon={icon}
            />
        </div>
    );
}

export default PhoneForm;
