import { ChangeEvent, useEffect, useState } from 'react';
import OptionInterface from '../../interfaces/OptionInterface';
import styles from './style.module.scss';

interface Props {
    name: string;
    label: string;
    value?: string;
    options: OptionInterface[];
    placeholder?: string;
    onChange?: (param: any) => void;
    onSelect?: (param: any) => void;
    isDisabled?: boolean;
    isRequired?: boolean;
}

function Select(props: Props) {
    const {
        label,
        name,
        value = '',
        placeholder = '',
        options,
        onSelect,
        onChange,
        isDisabled,
    } = props;
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        setSelectedValue(value);
    }, [value]);
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setSelectedValue(value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div className={styles.divSelect}>
            <label className={styles.labelSelect}>{label} :</label>
            <select
                name={name}
                value={selectedValue}
                className={styles.select}
                disabled={isDisabled}
                onChange={handleChange}
            >
                <option disabled value="">
                    {placeholder}
                </option>
                {options?.map((option: OptionInterface) => (
                    <option
                        key={name + option.name + option.label}
                        value={option.id || option.name}
                        onSelect={onSelect}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Select;
