import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '../../../../AlertProvider';
import { useAuth } from '../../../../AuthProvider';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import { alertType } from '../../../../constants/alertTypeConstant';
import {
    contactIdPage,
    messagesPage,
} from '../../../../constants/pagesRoutesContants';
import { successStatus } from '../../../../constants/requestStatusContants';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import {
    fetchUserMessagesByConversationId,
    postUserMessagesByConversationId,
} from '../../../../services/messageService';
import MessageType from '../../../../types/MessageType';
import UserType from '../../../../types/UserType';
import styles from './style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => void;
}

function Conversation(props: Props) {
    const { setToolbar } = props;
    const alert = useAlert();
    const navigate = useNavigate();
    const [messageText, setMessageText] = useState<string>('');
    const [isSearchOpened, setIsSearchOpened] = useState(false);
    const [searchMessageText, setSearchMessageText] = useState<string>('');
    const [conversationMessages, setConversationMessages] = useState<
        MessageType[]
    >([]);

    const { user } = useAuth();
    const params = useParams();
    const conversationId = params.id || '';

    const { isPending: isPendingMessages, data: conversation } = useQuery({
        queryKey: ['messages', conversationId, searchMessageText],
        queryFn: async () => {
            try {
                if (!conversationId) {
                    return [];
                }
                const { data, status } =
                    await fetchUserMessagesByConversationId(
                        conversationId,
                        searchMessageText
                    );

                if (!successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const { mutate } = useMutation({
        mutationFn: async () => {
            if (messageText.length > 0) {
                const { data, status } = await postUserMessagesByConversationId(
                    conversationId,
                    messageText
                );

                if (!successStatus.includes(status)) {
                    alert.show({
                        children:
                            'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                        type: alertType[status],
                    });
                }
                return data;
            }
        },
    });

    const handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMessageText(value);
    };

    const handleSearchMessage = (event: ChangeEvent<HTMLInputElement>) => {
        const { value = '' } = event.target;
        const searchInConversation = conversation?.messages?.filter(
            (x: MessageType) => {
                return x.text
                    .toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase());
            }
        );
        setSearchMessageText(value);
        setConversationMessages(searchInConversation);
    };

    useEffect(() => {
        setConversationMessages(conversation?.messages);
        const handleClickSearch = () => {
            setIsSearchOpened(!isSearchOpened);
        };
        const handleCloseConversation = () => {
            navigate(messagesPage.path);
        };
        const toolbar: NavDataInterface[] = [
            {
                id: 'toolbarBtnSearch',
                icon: 'fa-search',
                label: 'Chercher',
                position: 'end',
                onClick: handleClickSearch,
            },
            {
                id: 'toolbarBtnClose',
                icon: 'fa-remove',
                label: 'Fermer',
                position: 'end',
                onClick: handleCloseConversation,
            },
        ];

        conversation?.users?.forEach((recipient: UserType) => {
            toolbar.push({
                id: 'toolbarBtn' + recipient?.id,
                icon: 'fa-user',
                label: recipient?.firstName + ' ' + recipient?.lastName,
                position: 'start',
                link: contactIdPage.path + recipient?.id,
            });
        });

        setToolbar(toolbar);
    }, [
        conversationMessages,
        setToolbar,
        isSearchOpened,
        conversation,
        navigate,
    ]);

    if (isPendingMessages) {
        return <Loader />;
    }
    return (
        <div>
            <div className={styles.conversation}>
                {isSearchOpened && (
                    <Input label="Chercher" onChange={handleSearchMessage} />
                )}
                <div className={styles.messages}>
                    {conversationMessages?.map((msg: MessageType) => (
                        <div
                            key={'msg' + msg.id}
                            className={
                                user?.id === msg.senderId
                                    ? styles.right
                                    : styles.left
                            }
                        >
                            <div className={styles.msg}>
                                {user?.id !== msg.senderId &&
                                    conversation?.users?.lenght > 0 && (
                                        <strong className={styles.user}>
                                            {msg.sender}
                                        </strong>
                                    )}
                                <span>{msg.text}</span>
                                <label className={styles.date}>
                                    {new Date(
                                        msg.creationDate
                                    ).toLocaleString()}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.action}>
                    <div className={styles.write}>
                        <Input onChange={handleChangeMessage} />
                    </div>
                    <div className={styles.send}>
                        <Button onClick={mutate}>Envoyer</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Conversation;
