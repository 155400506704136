/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormResponseField } from '../../types/FormResponseField';
import styles from './style.module.scss';

interface Props {
    formName: string;
    formResponseFields: FormResponseField[];
}

function DynamicFormResponse(props: Props) {
    const { formResponseFields, formName } = props;
    console.log(formName);

    return (
        <div className={styles.formResponseContainer}>
            <table className={styles.formResponse}>
                {formResponseFields
                    .sort((a, b) => {
                        return a.position - b.position;
                    })
                    .map((field: FormResponseField) => (
                        <tr key={field.id}>
                            <td>{field.label}:</td>
                            <td className={styles.values}>
                                {field.value.map((item: string) => (
                                    <span>{item}</span>
                                ))}
                            </td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}
export default DynamicFormResponse;
