import {
    faChartBar,
    faCircleCheck,
    faFileLines,
    faLaptopCode,
    faMobileScreen,
    faShield,
    faTabletScreenButton,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

const Features = () => {
    return (
        <div className={styles.container}>
            {/* Hero Section */}
            <section className={styles.hero}>
                <div className={styles.heroContent}>
                    <h1 className={styles.heroTitle}>Gestion des dossiers</h1>
                    <p className={styles.heroSubtitle}>
                        Découvrez comment notre solution de gestion de dossiers
                        révolutionne le travail des avocats et améliore
                        l'expérience client.
                    </p>
                </div>
            </section>

            {/* Introduction */}
            <section className={styles.section}>
                <div className={styles.sectionContent}>
                    <div className={styles.introGrid}>
                        <div className={styles.introText}>
                            <h2 className={styles.sectionTitle}>
                                Centralisez tous vos documents juridiques
                            </h2>
                            <p className={styles.text}>
                                Notre solution de gestion de dossiers vous
                                permet de centraliser l'ensemble de vos
                                documents, de suivre les échéances et de
                                visualiser l'avancement de chaque affaire en
                                temps réel.
                            </p>
                            <p className={styles.text}>
                                Conçue spécifiquement pour les professionnels du
                                droit, notre plateforme s'adapte à tous les
                                types de cabinets et à toutes les spécialités
                                juridiques.
                            </p>
                            <div className={styles.tagContainer}>
                                <span
                                    className={`${styles.tag} ${styles.tagOrange}`}
                                >
                                    Organisation
                                </span>
                                <span
                                    className={`${styles.tag} ${styles.tagBlue}`}
                                >
                                    Productivité
                                </span>
                                <span
                                    className={`${styles.tag} ${styles.tagGreen}`}
                                >
                                    Collaboration
                                </span>
                                <span
                                    className={`${styles.tag} ${styles.tagPurple}`}
                                >
                                    Sécurité
                                </span>
                            </div>
                        </div>
                        <div className={styles.introImage}>
                            <img
                                src="/img/bureaucracy-2106924_1280.jpg"
                                alt="Gestion des dossiers"
                                className={styles.image}
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Caractéristiques principales */}
            <section className={`${styles.section} ${styles.altBg}`}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        Caractéristiques principales
                    </h2>

                    <div className={styles.featuresGrid}>
                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIcon} ${styles.blueIcon}`}
                            >
                                <FontAwesomeIcon icon={faFileLines} />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Stockage sécurisé
                            </h3>
                            <p className={styles.featureText}>
                                Tous vos documents sont stockés de manière
                                sécurisée avec un chiffrement de bout en bout et
                                des sauvegardes automatiques.
                            </p>
                            <ul className={styles.featureList}>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Stockage illimité de documents</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Chiffrement AES-256</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Sauvegardes automatiques quotidiennes
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIcon} ${styles.orangeIcon}`}
                            >
                                <FontAwesomeIcon icon={faShield} />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Contrôle des accès
                            </h3>
                            <p className={styles.featureText}>
                                Définissez précisément qui peut accéder à quels
                                documents et quelles actions peuvent être
                                effectuées.
                            </p>
                            <ul className={styles.featureList}>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Gestion des permissions par dossier
                                    </span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Journalisation des accès</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Authentification à deux facteurs
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIcon} ${styles.blueIcon}`}
                            >
                                <FontAwesomeIcon icon={faUsers} />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Collaboration
                            </h3>
                            <p className={styles.featureText}>
                                Travaillez efficacement en équipe sur les
                                dossiers et partagez facilement des documents
                                avec vos clients.
                            </p>
                            <ul className={styles.featureList}>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Édition collaborative en temps réel
                                    </span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Commentaires et annotations</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Partage sécurisé avec les clients
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIcon} ${styles.orangeIcon}`}
                            >
                                <FontAwesomeIcon icon={faChartBar} />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Suivi et rapports
                            </h3>
                            <p className={styles.featureText}>
                                Suivez l'avancement de vos dossiers et générez
                                des rapports détaillés pour une meilleure
                                gestion.
                            </p>
                            <ul className={styles.featureList}>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Tableaux de bord personnalisables
                                    </span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Rapports d'activité exportables</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Suivi du temps passé par dossier
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIcon} ${styles.blueIcon}`}
                            >
                                <FontAwesomeIcon icon={faLaptopCode} />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Automatisation
                            </h3>
                            <p className={styles.featureText}>
                                Automatisez les tâches répétitives pour gagner
                                du temps et réduire les erreurs.
                            </p>
                            <ul className={styles.featureList}>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>
                                        Génération automatique de documents
                                    </span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Rappels et notifications</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Workflows personnalisables</span>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.featureCard}>
                            <div
                                className={`${styles.featureIcon} ${styles.orangeIcon}`}
                            >
                                <FontAwesomeIcon icon={faMobileScreen} />
                            </div>
                            <h3 className={styles.featureTitle}>
                                Accessibilité mobile
                            </h3>
                            <p className={styles.featureText}>
                                Accédez à vos dossiers depuis n'importe où grâce
                                à notre application mobile performante.
                            </p>
                            <ul className={styles.featureList}>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Applications iOS et Android</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Synchronisation automatique</span>
                                </li>
                                <li className={styles.featureItem}>
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className={styles.checkIcon}
                                    />
                                    <span>Mode hors connexion</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* Témoignages */}
            <section className={styles.section}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        Ce que nos utilisateurs en disent
                    </h2>

                    <div className={styles.testimonialsGrid}>
                        <div className={styles.testimonialCard}>
                            <div className={styles.testimonialHeader}>
                                <div className={styles.testimonialAvatar}></div>
                                <div className={styles.testimonialAuthor}>
                                    <h4>Maître Sophie Durand</h4>
                                    <p>Avocate en droit de la famille</p>
                                </div>
                            </div>
                            <p className={styles.testimonialText}>
                                "La gestion des dossiers sur Liber'All a
                                complètement transformé mon organisation. Je
                                gagne un temps précieux et mes clients
                                apprécient de pouvoir suivre l'avancement de
                                leur affaire."
                            </p>
                        </div>

                        <div className={styles.testimonialCard}>
                            <div className={styles.testimonialHeader}>
                                <div className={styles.testimonialAvatar}></div>
                                <div className={styles.testimonialAuthor}>
                                    <h4>Maître Thomas Martin</h4>
                                    <p>Avocat en droit des affaires</p>
                                </div>
                            </div>
                            <p className={styles.testimonialText}>
                                "L'automatisation des tâches répétitives et la
                                possibilité de collaborer efficacement avec mon
                                équipe ont considérablement amélioré notre
                                productivité. Un outil indispensable."
                            </p>
                        </div>

                        <div className={styles.testimonialCard}>
                            <div className={styles.testimonialHeader}>
                                <div className={styles.testimonialAvatar}></div>
                                <div className={styles.testimonialAuthor}>
                                    <h4>Cabinet Legrand & Associés</h4>
                                    <p>Cabinet d'avocats multidisciplinaire</p>
                                </div>
                            </div>
                            <p className={styles.testimonialText}>
                                "Nous utilisons Liber'All depuis plus d'un an et
                                la différence est flagrante. La gestion des
                                accès et la sécurité des données nous ont
                                convaincus, et nos clients sont ravis de la
                                transparence."
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Compatibilité */}
            <section className={`${styles.section} ${styles.altBg}`}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.sectionTitle}>
                        Compatible avec tous vos appareils
                    </h2>
                    <p className={styles.sectionSubtitle}>
                        Accédez à vos dossiers depuis n'importe quel appareil, à
                        tout moment et en tout lieu.
                    </p>

                    <div className={styles.devicesContainer}>
                        <div className={styles.deviceItem}>
                            <div
                                className={`${styles.deviceIcon} ${styles.blueIcon}`}
                            >
                                <FontAwesomeIcon icon={faLaptopCode} />
                            </div>
                            <h3 className={styles.deviceTitle}>Ordinateur</h3>
                            <p className={styles.deviceText}>
                                Windows, Mac, Linux
                            </p>
                        </div>

                        <div className={styles.deviceItem}>
                            <div
                                className={`${styles.deviceIcon} ${styles.orangeIcon}`}
                            >
                                <FontAwesomeIcon icon={faTabletScreenButton} />
                            </div>
                            <h3 className={styles.deviceTitle}>Tablette</h3>
                            <p className={styles.deviceText}>iPad, Android</p>
                        </div>

                        <div className={styles.deviceItem}>
                            <div
                                className={`${styles.deviceIcon} ${styles.blueIcon}`}
                            >
                                <FontAwesomeIcon icon={faMobileScreen} />
                            </div>
                            <h3 className={styles.deviceTitle}>Smartphone</h3>
                            <p className={styles.deviceText}>iPhone, Android</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA */}
            <section className={styles.ctaSection}>
                <div className={styles.sectionContent}>
                    <h2 className={styles.ctaTitle}>
                        Prêt à optimiser la gestion de vos dossiers ?
                    </h2>
                    <p className={styles.ctaText}>
                        Rejoignez les milliers d'avocats qui utilisent déjà
                        Liber'All pour simplifier leur quotidien et améliorer
                        leur relation client.
                    </p>
                    <div className={styles.ctaButtons}>
                        <button className={styles.btnWhite}>
                            Essai gratuit de 30 jours
                        </button>
                        <button className={styles.btnOrange}>
                            Demander une démo
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Features;
