import { useQuery } from '@tanstack/react-query';
import { Icon } from 'leaflet';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import location from '../../assets/location.svg';
import Map from '../../components/Map';
import SearchBar from '../../components/SearchBar';
import { classNames } from '../../helpers/classNames';
import MapDataInterface from '../../interfaces/MapDataInterface';
import { fetchLawyers } from '../../services/lawyerService';
import AddressType from '../../types/AddressType';
import LawyerType from '../../types/LawyerType';
import LawyerCard from './lawyerCard';
import LawyerPopup from './lawyerPopup';
import NoLawyerFound from './noLawyerFound';
import styles from './style.module.scss';

function Lawyers() {
    const [searchParams] = useSearchParams();
    const specialityFromUrl = searchParams.get('speciality') || '';
    const addressFromUrl = searchParams.get('address') || '';
    const latitudeFromUrl = searchParams.get('latitude') || '';
    const longitudeFromUrl = searchParams.get('longitude') || '';
    const lawyerFromUrl = searchParams.get('lawyer') || '';
    const lawfirmFromUrl = searchParams.get('lawfirm') || '';
    const [params, setParams] = useState<{ [key: string]: string | undefined }>(
        {}
    );
    const [inputValue, setInputValue] = useState<{
        [key: string]: string | undefined;
    }>({});
    const [lawyersList, setLawyersList] = useState<LawyerType[]>([]);

    useEffect(() => {
        const params: { [key: string]: string } = {};
        if (specialityFromUrl) {
            params['speciality'] = specialityFromUrl;
        }
        if (addressFromUrl && latitudeFromUrl && longitudeFromUrl) {
            params['address'] = addressFromUrl;
            params['latitude'] = latitudeFromUrl;
            params['longitude'] = longitudeFromUrl;
        }
        if (lawyerFromUrl) {
            params['lawyer'] = lawyerFromUrl;
        }
        if (lawfirmFromUrl) {
            params['lawfirm'] = lawfirmFromUrl;
        }
        setParams(params);
    }, [
        setParams,
        specialityFromUrl,
        addressFromUrl,
        lawyerFromUrl,
        lawfirmFromUrl,
        latitudeFromUrl,
        longitudeFromUrl,
    ]);

    const { isPending: isPendingLayers } = useQuery({
        queryKey: ['lawyers', params],
        queryFn: async () => {
            try {
                const { data } = await fetchLawyers(params);
                setLawyersList(data);
            } catch (error) {
                console.error(error);
                throw error;
            }
            return [];
        },
    });

    const lawyerIcon = new Icon({
        iconUrl: location,
        iconSize: [32, 32],
    });

    if (isPendingLayers && lawyersList?.length < 1) {
        return <div>Loading...</div>;
    }

    const mapData: MapDataInterface[] = lawyersList?.map(
        (lawyer: LawyerType) => {
            return {
                id: lawyer.id,
                label: `${lawyer.firstName} ${lawyer.lastName?.toLocaleUpperCase()}`,
                latitude: lawyer.latitude,
                longitude: lawyer.longitude,
                link: `#${lawyer.id}`,
                icon: lawyerIcon,
                popup: <LawyerPopup lawyer={lawyer} />,
            };
        }
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newParams = { ...params };

        newParams[name] = '';
        if (value.length !== 0) {
            newParams[name] = value;
        } else {
            newParams[name] = undefined;
            setParams(newParams);
        }
        setInputValue(newParams);
    };

    const handleSetUserLocation = (data: AddressType) => {
        const newParam = { ...params };
        newParam['address'] = data.address;
        newParam['longitude'] = data.longitude?.toString();
        newParam['latitude'] = data.latitude?.toString();
        setParams(newParam);
    };

    const userLocation: AddressType = {
        address: params['address'],
        longitude: Number(params['longitude']),
        latitude: Number(params['latitude']),
    };

    return (
        <div className={classNames([styles.lawyers])}>
            <div className={styles.map}>
                <Map
                    data={mapData}
                    userLocation={userLocation}
                    setUserLocation={handleSetUserLocation}
                />
            </div>
            <div className={styles.searchBarContainer}>
                <SearchBar
                    setParams={setParams}
                    params={inputValue}
                    onChange={handleChange}
                />
            </div>
            <div className={styles.listContainer}>
                {lawyersList?.length > 0 ? (
                    lawyersList?.map((lawyer: LawyerType) => (
                        <LawyerCard
                            lawyer={lawyer}
                            key={lawyer.id}
                            specialityId={params.speciality}
                        />
                    ))
                ) : (
                    <NoLawyerFound />
                )}
            </div>
        </div>
    );
}

export default Lawyers;
