import { faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Button from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';
import { fetchFormsResponsesByClientCaseId } from '../../../../../services/formResponseService';
import CaseType from '../../../../../types/CaseType';
import ClientCaseFormModal from './clientCaseFormModal';
import ClientCaseFormsResponsesTable from './clientCaseFormResponsesTable';
import styles from './style.module.scss';

interface Props {
    clientCase: CaseType;
}

function ClientCaseFormResponses(props: Props) {
    const { clientCase } = props;
    const [modalOpened, setModalOpened] = useState(false);

    const { isPending: isPendingForms, data: formResponses } = useQuery({
        queryKey: ['formsResponses'],
        queryFn: async () => {
            try {
                const { data } = await fetchFormsResponsesByClientCaseId(
                    clientCase.id
                );
                return data || [];
            } catch (error) {
                console.error(error);
            }
            return [];
        },
    });

    const handleOpenSendForm = () => {
        setModalOpened(true);
    };

    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Formulaires:</span>
                <Button onClick={handleOpenSendForm}>
                    <FontAwesomeIcon icon={faFileText} />
                    Envoyer un formulaire
                </Button>
            </h2>
            <ClientCaseFormModal
                modalOpened={modalOpened}
                setModalOpened={setModalOpened}
                clientCase={clientCase}
            />
            <div className={styles.table}>
                {isPendingForms ? (
                    <Loader />
                ) : (
                    formResponses.length > 0 && (
                        <ClientCaseFormsResponsesTable
                            formResponses={formResponses}
                        />
                    )
                )}
            </div>
        </div>
    );
}

export default ClientCaseFormResponses;
