import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ButtonLink from '../../../../components/ButtonLink';
import FaIcon from '../../../../components/Icons/FaIcon';
import Modal from '../../../../components/Modal';
import { informationFormsPage } from '../../../../constants/pagesRoutesContants';
import Role from '../../../../enums/RoleEnum';
import { fetchLawyerAvailabilities } from '../../../../services/availabilityService';
import { fetchUserMeetings } from '../../../../services/meetingService';
import LawyerType from '../../../../types/LawyerType';
import UserType from '../../../../types/UserType';
import LawyerMeetingModal from '../modal';
import styles from './style.module.scss';

interface Props {
    lawyer: LawyerType;
}

function LawyerCalendar(props: Props) {
    const { lawyer } = props;
    const user: UserType = useOutletContext();
    const [modalOpened, setModalOpened] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const { data: availabilities } = useQuery({
        queryKey: ['availabilities'],
        queryFn: async () => {
            const { data } = await fetchLawyerAvailabilities(lawyer.id);
            return data;
        },
    });

    const { data: meetings } = useQuery({
        queryKey: ['lawyerMeetings'],
        queryFn: async () => {
            const { data } = await fetchUserMeetings(lawyer.id);
            return data;
        },
    });

    const dateNow = new Date();
    const dateZero = new Date(0);

    meetings?.push({
        start: dateZero,
        end: dateNow,
        display: 'background',
        backgroundColor: '#ff000080',
    });

    const headerToolbar = {
        start: 'prev,today', // will normally be on the left. if RTL, will be on the right
        center: 'title',
        end: `${user?.role === Role.Lawyer ? 'listDay,' : ''}next`, // will normally be on the right. if RTL, will be on the left
    };

    const onEventClick = (info: any) => {
        alert(
            "Le créneau horaire selectionné n'est pas disponible. Veuillez en selectionner un autre."
        );
    };

    const onDateSelected = (info: any) => {
        const selectedTime = new Date(info.start);
        if (selectedTime < new Date()) {
            alert(
                "Le créneau horaire selectionné n'est plus disponible. Veuillez en selectionner un autre."
            );
            return;
        }
        setStartDate(selectedTime);
        setModalOpened(true);
    };

    const weekendAvailabilities = availabilities?.filter(
        (availability: any) => {
            return (
                availability.daysOfWeek.includes(0) ||
                availability.daysOfWeek.includes(6)
            );
        }
    );
    const weekendWorked = weekendAvailabilities?.length > 0;
    const today = new Date();
    const initialDate =
        !weekendWorked && today.getDay() === 6
            ? today.setDate(today.getDate() + 1)
            : today;
    return (
        <div className={styles.containerCalendar}>
            {!lawyer?.isLawyerEnrolled ? (
                <div className={styles.notEnrolled}>
                    <h3>L'avocat(e) n'est pas inscrit(e) sur notre site.</h3>
                    <span>Veuillez le/la contacter directement.</span>
                    <p>
                        <span>
                            Vous pouvez constituer votre dossier à remettre lors
                            de votre rendez-vous
                        </span>
                        <ButtonLink
                            link={informationFormsPage.path}
                            variant={'secondary'}
                        >
                            Constituer mon dossier
                        </ButtonLink>
                    </p>
                </div>
            ) : (
                <Modal opened={modalOpened} setOpened={setModalOpened}>
                    <LawyerMeetingModal
                        lawyerName={`${
                            lawyer.firstName
                        } ${lawyer.lastName?.toLocaleUpperCase()}`}
                        lawyerId={lawyer.id}
                        prestations={lawyer.prestations}
                        startDate={startDate}
                        setIsModalOpened={setModalOpened}
                    />
                </Modal>
            )}
            <div className={styles.enrolled}>
                <p>
                    <FaIcon>fa-arrow-pointer</FaIcon> Cliquez sur le calendrier
                    pour choisir un créneau horaire
                </p>
                <div className={styles.legends}>
                    <div className={styles.legendUnallow}></div>
                    <span>Créneaux horaires indisponibles</span>
                    <div className={styles.legendNow}></div>
                    <span>Aujourd'hui</span>
                </div>
                <FullCalendar
                    plugins={[
                        timeGridPlugin,
                        dayGridPlugin,
                        listPlugin,
                        interactionPlugin,
                    ]}
                    initialDate={initialDate}
                    initialView={'timeGridWeek'}
                    businessHours={availabilities}
                    events={meetings}
                    eventClick={onEventClick}
                    selectable={true}
                    weekends={weekendWorked}
                    select={onDateSelected}
                    nowIndicator={true}
                    locale={'fr'}
                    headerToolbar={headerToolbar}
                    selectConstraint={'businessHours'}
                    allDaySlot={false}
                />
            </div>
        </div>
    );
}

export default LawyerCalendar;
