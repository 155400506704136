import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import ButtonLink from '../../../../components/ButtonLink';
import LoaderDot from '../../../../components/LoaderDot';
import { fetchClientCasesByUserId } from '../../../../services/caseService';
import CaseType from '../../../../types/CaseType';
import styles from './style.module.scss';

interface Props {
    userId: string;
}

function CasesTable(props: Props) {
    const { userId } = props;
    // ajouter une page de détails des étapes du dossier

    const { data: cases, isPending: clientCasePending } = useQuery({
        queryKey: ['getClientCaseByUserId', userId],
        queryFn: async () => {
            if (!userId) {
                return null;
            }
            const { data } = await fetchClientCasesByUserId(userId);
            return data;
        },
    });

    if (clientCasePending) {
        return <LoaderDot />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Dossier</th>
                            <th scope="col">Date de création</th>
                            <th scope="col">status</th>
                            {/* <th scope="col">nombre d'heure travaillée</th> */}
                            {/* <th scope="col">Dernière ouverture</th> */}
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody className={styles.events}>
                        {cases?.map((clientCase: CaseType) => (
                            <tr className={styles.event} key={clientCase.id}>
                                <td className={styles.iconCol}>
                                    <FontAwesomeIcon icon={faFolder} />
                                </td>
                                <td>
                                    {clientCase.caseName || clientCase.caseRef}
                                </td>
                                <td>
                                    {clientCase.creationDate?.toLocaleDateString()}
                                </td>
                                <td>{clientCase.status}</td>
                                {/* <td>{clientCase.timeSpend}</td> */}
                                <td>
                                    <ButtonLink link={'case/' + clientCase.id}>
                                        Ouvrir
                                    </ButtonLink>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CasesTable;
