import CalendarWidget from '../components/Widgets/CalendarWidget';
import ClientsCasesBookmarkWidget from '../components/Widgets/ClientsCasesBookmarkWidget';
import ClientsCasesGraphWidget from '../components/Widgets/ClientsCasesGraphWidget';
import ContactClientsBookmarkWidget from '../components/Widgets/ContactClientsBookmarkWidget';
import ContactLawyersBookmarkWidget from '../components/Widgets/ContactLawyersBookmarkWidget';
import LawfirmEvents from '../components/Widgets/LawfirmEvents';
import NewClientsCasesWidget from '../components/Widgets/NewClientsCasesWidget';
import NewMessagesWidget from '../components/Widgets/NewMessagesWidget';
import NextMeetingsWidget from '../components/Widgets/NextMeetingsWidget';
import NextWeekJudicialCalendarWidget from '../components/Widgets/NextWeekJudicialCalendarWidget';
import SpecialitiesGraphWidget from '../components/Widgets/SpecialitiesGraphWidget';
import StopwatchWidget from '../components/Widgets/StopwatchWidget';
import TimerWidget from '../components/Widgets/TimerWidget';
import TodayJudicialCalendarWidget from '../components/Widgets/TodayJudicialCalendarWidget';

export const widgets: { [key: string]: any } = {
    CalendarWidget: CalendarWidget,
    ClientsCasesBookmarkWidget: ClientsCasesBookmarkWidget,
    ClientsCasesGraphWidget: ClientsCasesGraphWidget,
    ContactLawyersBookmarkWidget: ContactLawyersBookmarkWidget,
    ContactClientsBookmarkWidget: ContactClientsBookmarkWidget,
    NextMeetingsWidget: NextMeetingsWidget,
    NextWeekJudicialCalendarWidget: NextWeekJudicialCalendarWidget,
    NewMessagesWidget: NewMessagesWidget,
    NewClientsCasesWidget: NewClientsCasesWidget,
    SpecialitiesGraphWidget: SpecialitiesGraphWidget,
    StopwatchWidget: StopwatchWidget,
    TimerWidget: TimerWidget,
    TodayJudicialCalendarWidget: TodayJudicialCalendarWidget,
    LawfirmEvents: LawfirmEvents,
};
