import { useNavigate } from 'react-router-dom';
import Toolbar from '../../../../components/Toolbar';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import styles from './style.module.scss';

function ClientBillDetails() {
    const navigate = useNavigate();

    const lawyerToolbar: NavDataInterface[] = [
        {
            id: 'caseToolbar0',
            iconType: 'regular',
            icon: 'fa-chevron-left',
            label: 'Retour',
            onClick: () => navigate(-1),
        },
    ];

    return (
        <div className={styles.body}>
            <Toolbar toolbarData={lawyerToolbar} />
        </div>
    );
}

export default ClientBillDetails;
