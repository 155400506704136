import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    ScrollRestoration,
} from 'react-router-dom';
import { AlertProvider } from './AlertProvider';
import './App.scss';
import { AuthProvider } from './AuthProvider';
import {
    availabilitiesPage,
    billingPage,
    cguvPage,
    clientsBillIdPage,
    clientsCaseIdPage,
    clientsIdPage,
    clientsPage,
    contactPage,
    dashboardPage,
    enrollLawyerPage,
    featuresPage,
    forgotPage,
    formsCreatePage,
    formsEditPage,
    formsPage,
    homePage,
    informationFormsIdPage,
    informationFormsPage,
    lawfirmSettingsPage,
    lawfirmsIdPage,
    lawfirmsPage,
    lawyersIdPage,
    lawyersPage,
    liberallContactPage,
    loginPage,
    meetingsPage,
    messagesPage,
    myCasesIdPage,
    myCasesPage,
    notePage,
    offersPage,
    pricingPage,
    registerPage,
    resetPage,
    servicesPage,
    settingsPage,
    statisticsPage,
} from './constants/pagesRoutesContants';
import Role from './enums/RoleEnum';
import ForgotPassword from './pages/account/forgorPassword/page';
import Login from './pages/account/login/page';
import Authentication from './pages/account/page';
import Register from './pages/account/register/page';
import ResetPassword from './pages/account/resetPassword/page';
import Cguv from './pages/cguv/page';
import Features from './pages/features/page';
import Home from './pages/Home/page';
import InformationForm from './pages/informationForm/page';
import Lawfirm from './pages/lawfirms/lawfirm/page';
import Lawfirms from './pages/lawfirms/page';
import EnrrollLawyer from './pages/lawyers/enrollLawyer/page';
import Lawyer from './pages/lawyers/lawyer/page';
import Lawyers from './pages/lawyers/page';
import LiberAllContact from './pages/liberallContact/page';
import Offers from './pages/offers/page';
import Pricing from './pages/pricing/page';
import Availabilities from './pages/profile/availabilities/page';
import Billing from './pages/profile/billing/page';
import MyCases from './pages/profile/cases/myCases/page';
import ClientBillDetails from './pages/profile/clients/clientBillDetails/page';
import ClientCaseDetails from './pages/profile/clients/clientCaseDetails/page';
import Clients from './pages/profile/clients/page';
import Contact from './pages/profile/contact/page';
import Dashboard from './pages/profile/dashboard/page';
import EditorForm from './pages/profile/formEditor/EditorForm/page';
import FormsEditor from './pages/profile/formEditor/page';
import LawfirmSettings from './pages/profile/lawfimSettings/page';
import Meetings from './pages/profile/meetings/page';
import Message from './pages/profile/messages/page';
import Notes from './pages/profile/notes/page';
import Profile from './pages/profile/page';
import Services from './pages/profile/services/page';
import Settings from './pages/profile/settings/page';
import Statistics from './pages/profile/statistics';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const queryClient = new QueryClient();

function App() {
    const AppLayout = () => (
        <>
            <ScrollRestoration />
            <Outlet />
        </>
    );
    const router = createBrowserRouter([
        {
            element: <AppLayout />,
            children: [
                {
                    path: homePage.path,
                    element: (
                        <PublicRoute>
                            <Home />
                        </PublicRoute>
                    ),
                },
                {
                    element: (
                        <PublicRoute>
                            <Authentication />
                        </PublicRoute>
                    ),
                    children: [
                        { path: loginPage.path, element: <Login /> },
                        { path: registerPage.path, element: <Register /> },
                        { path: resetPage.path, element: <ResetPassword /> },
                        { path: forgotPage.path, element: <ForgotPassword /> },
                    ],
                },
                {
                    path: lawyersPage.path,
                    element: (
                        <PublicRoute>
                            <Lawyers />
                        </PublicRoute>
                    ),
                },
                {
                    path: lawyersIdPage.path + ':lawyerId',
                    element: (
                        <PublicRoute>
                            <Lawyer />
                        </PublicRoute>
                    ),
                },
                {
                    path: informationFormsPage.path,
                    element: (
                        <PublicRoute>
                            <InformationForm />
                        </PublicRoute>
                    ),
                },
                {
                    path: informationFormsIdPage.path + ':formId',
                    element: (
                        <PublicRoute>
                            <InformationForm />
                        </PublicRoute>
                    ),
                },
                {
                    path: lawfirmsPage.path,
                    element: (
                        <PublicRoute>
                            <Lawfirms />
                        </PublicRoute>
                    ),
                },
                {
                    path: lawfirmsIdPage.path + ':lawfirmId',
                    element: (
                        <PublicRoute>
                            <Lawfirm />
                        </PublicRoute>
                    ),
                },

                {
                    path: cguvPage.path,
                    element: (
                        <PublicRoute>
                            <Cguv />
                        </PublicRoute>
                    ),
                },
                {
                    path: pricingPage.path,
                    element: (
                        <PrivateRoute roles={[Role.Administrator]}>
                            <PublicRoute>
                                <Pricing />
                            </PublicRoute>
                        </PrivateRoute>
                    ),
                },
                {
                    path: offersPage.path,
                    element: (
                        <PrivateRoute roles={[Role.Administrator]}>
                            <PublicRoute>
                                <Offers />
                            </PublicRoute>
                        </PrivateRoute>
                    ),
                },
                {
                    path: featuresPage.path,
                    element: (
                        // <PrivateRoute roles={[Role.Administrator]}>
                        <PublicRoute>
                            <Features />
                        </PublicRoute>
                        // </PrivateRoute>
                    ),
                },
                {
                    path: liberallContactPage.path,
                    element: (
                        <PublicRoute>
                            <LiberAllContact />
                        </PublicRoute>
                    ),
                },
                {
                    path: enrollLawyerPage.path,
                    element: (
                        <PrivateRoute roles={[Role.Administrator]}>
                            <PublicRoute>
                                <EnrrollLawyer />
                            </PublicRoute>
                        </PrivateRoute>
                    ),
                },
                {
                    element: <Profile />,
                    children: [
                        {
                            path: dashboardPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Dashboard />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: clientsPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Clients />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: clientsIdPage.path + ':clientId',
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Clients />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: clientsCaseIdPage.path + ':caseId',
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <ClientCaseDetails />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: clientsBillIdPage.path + ':billId',
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <ClientBillDetails />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: myCasesPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <MyCases />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: myCasesIdPage.path + ':caseId',
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <MyCases />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: meetingsPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Meetings />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: settingsPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Settings />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: formsPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <FormsEditor />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: formsCreatePage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <EditorForm />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: formsEditPage.path + ':formId',
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <EditorForm />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: messagesPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Message />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: contactPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.User,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Contact />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: availabilitiesPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Availabilities />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: notePage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Notes />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: servicesPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Services />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: billingPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Billing />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: statisticsPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <Statistics />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: lawfirmSettingsPage.path,
                            element: (
                                <PrivateRoute
                                    roles={[
                                        Role.Lawyer,
                                        Role.LawfirmAdministrator,
                                        Role.Administrator,
                                    ]}
                                >
                                    <LawfirmSettings />
                                </PrivateRoute>
                            ),
                        },
                    ],
                },
            ],
        },
    ]);

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <AlertProvider>
                    <RouterProvider router={router} />
                </AlertProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
