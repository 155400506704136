import { useQuery } from '@tanstack/react-query';
import { contactIdPage } from '../../../constants/pagesRoutesContants';
import { fetchContactClientsBookmark } from '../../../services/bookmarkService';
import UserConversationType from '../../../types/UserConversationType';
import FaIcon from '../../Icons/FaIcon';
import LoaderDot from '../../LoaderDot';
import WidgetCard from '../WidgetCard';
import styles from './style.module.scss';
function ContactClientsBookmarkWidget() {
    const { data, isPending } = useQuery({
        queryKey: ['getContactBookmark'],
        queryFn: async () => {
            const { data } = await fetchContactClientsBookmark();
            return data;
        },
    });

    if (isPending) {
        return <LoaderDot />;
    }

    return (
        <>
            <WidgetCard title="Clients" isPending={isPending}>
                <div className={styles.containerFav}>
                    {data?.map((item: UserConversationType) => (
                        <a
                            href={contactIdPage.path + item.id}
                            key={item.id}
                            className={styles.fav}
                        >
                            <FaIcon>fa-envelope</FaIcon>
                            <span className={styles.favName}>{item.name}</span>
                        </a>
                    ))}
                </div>
            </WidgetCard>
        </>
    );
}

export default ContactClientsBookmarkWidget;
