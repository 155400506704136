import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../../../components/Button';
import Element from '../../../../../../components/Element';
import FormElementType from '../../../../../../types/FormElementType';
import styles from './style.module.scss';

interface Props {
    form: FormElementType[];
    setForm: (param: FormElementType[]) => void;
    element: FormElementType;
}

function FormElement(props: Props) {
    const { form, setForm, element } = props;

    const handleRemoveElement = () => {
        const indexValue = form.indexOf(element);
        const newFormData = [...form];
        newFormData.splice(indexValue, 1);
        setForm(newFormData);
    };

    return (
        <div
            className={styles.elementContainer}
            key={element.formComponentElement + element.name}
        >
            <div className={styles.removeElement}>
                <Button variant="secondaryLight" onClick={handleRemoveElement}>
                    <FontAwesomeIcon icon={faXmark} color="red" size={'2x'} />
                </Button>
            </div>
            <Element element={element} />
        </div>
    );
}

export default FormElement;
