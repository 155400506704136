import { formResponseEndpoint as endpoint } from '../constants/endpointsContants';
import SendFormInterface from '../interfaces/SendFormInterface';
import SendFormResponseInterface from '../interfaces/SendFormResponseInterface';
import request from './genericRequestService';

async function sendForm(data: SendFormInterface) {
    return await request.post({ endpoint: endpoint + '/send', data });
}
async function postFormResponse(data: SendFormResponseInterface) {
    const { id, response } = data;
    return await request.post({ endpoint, id, data: response });
}

async function fetchFormResponseById(id: string) {
    return await request.get({ endpoint, id });
}

async function fetchFormsResponsesByUserId(userId: string) {
    return await request.get({ endpoint: endpoint + '/user', id: userId });
}

async function fetchFormsResponsesByClientCaseId(clientCaseId: string) {
    return await request.get({
        endpoint: endpoint + '/clientCase',
        id: clientCaseId,
    });
}

export {
    fetchFormResponseById,
    fetchFormsResponsesByClientCaseId,
    fetchFormsResponsesByUserId,
    postFormResponse,
    sendForm,
};
